<template>
  <div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden sm:rounded">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Codigo</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Empleado</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Sueldo</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Septimo dia</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Prima dominical</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Gratificacion</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Retroactivo</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Vacaciones a tiempo</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Vacaciones reportadas</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Prima de vacaciones a tiempo</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Prima de vacaciones reportada</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Aguinaldo</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Total percepciones</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Seguro de vivienda infonavit</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Subsidio al empleado (sp)</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Subs al empleo (mes)</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">ISR</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">IMSS</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Prestamo infonavit</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Prestamo empresa</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Ajuste en subsidio para el empleo</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Subs entregado que no correspondia</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Ajuste al neto</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">ISR de ajuste mensual</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">ISR ajustado por subsidio</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Ajuste al subsidio causado</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Desc. faltantes en cortes</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Desc. multa</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Desc. producto inv.</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Total deducciones</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Neto</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <ItemNominaFiscal v-for="(registro, key) in registros" :registro="registro" :key="key" class="bg-white odd:bg-gray-100" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemNominaFiscal from './ItemNominaFiscal'
export default {
  name: 'ListNominaFiscal',
  props: {
    registros: Array
  },
  components: {
    ItemNominaFiscal
  }
}
</script>
