import Vue from 'vue'
import Vuex from 'vuex'
import authClient from '@/utils/auth'
import moment from 'moment-timezone'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: authClient.getToken() || '',
    empresa: parseInt(localStorage.getItem('empresa')) || 0,
    fechaTurno: moment().format()
  },
  getters: {
    isAuthenticated: state => !!state.token,
    empresaSeleccionada: state => state.empresa,
    getFechaTurno: state => state.fechaTurno
  },
  mutations: {
    AUTH_LOGIN (state, token) {
      state.token = token
    },
    AUTH_LOGOUT (state) {
      state.token = ''
    },
    EMPRESA_SELECCIONADA (state, empresa) {
      state.empresa = empresa
    },
    FECHA_TURNO (state, fecha) {
      state.fechaTurno = fecha
    }
  },
  actions: {
    AUTH_LOGIN ({ commit }, user) {
      return new Promise((resolve, reject) => {
        authClient.login(user)
          .then((response) => {
            const token = response
            if (!token) {
              return resolve(token)
            }
            authClient.setToken(token)
            commit('AUTH_LOGIN', token)
            resolve(!!token)
          })
          .catch(err => {
            authClient.logout()
            reject(err)
          })
      })
    },
    AUTH_LOGOUT ({ commit }) {
      authClient.logout()
      localStorage.removeItem('empresa')
      commit('EMPRESA_SELECCIONADA', 0)
      commit('AUTH_LOGOUT')
    },
    EMPRESA_SELECCIONADA ({ commit }, empresa) {
      localStorage.setItem('empresa', empresa)
      commit('EMPRESA_SELECCIONADA', empresa)
    },
    FECHA_TURNO ({ commit }, fecha) {
      commit('FECHA_TURNO', fecha)
    }
  },
  modules: {
  }
})
