<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link :to="{ name: 'empleado-vacaciones', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-6 mb-10">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createVacacion">
          <div class="shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-12">
                  <p class="uppercase text-base text-gray-700 font-medium tracking-wider">Nueva Solicitud Vacaciones</p>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-4">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Dias de disponibles</label>
                  <input v-model="vacacionesDisponibles" readonly class="input-readonly">
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Fecha Inicial</label>
                  <datepicker v-model="fechaInicial" :language="es" :format="format"></datepicker>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Fecha Final</label>
                  <datepicker v-model="fechaFinal" :language="es" :format="format"></datepicker>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12">
                  <button :disabled="this.vacacionesDisponibles === 0" class="disabled:opacity-75 py-1 px-4 border border-transparent text-sm leading-5 rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none">
                    Registrar Vacaciones
                  </button>
                  <span v-show="errorCaptura" class="text-red-600 text-sm ml-6">Error al registrar vacaciones, verificar datos.</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/src/locale'
import moment from 'moment-timezone'
import authClient from '@/utils/auth'
export default {
  name: 'NuevoVacaciones',
  components: {
    Datepicker
  },
  data () {
    return {
      es: es,
      format: 'd MMMM yyyy',
      vacacionesDisponibles: 0,
      fechaInicial: '',
      fechaFinal: '',
      errorCaptura: false
    }
  },
  methods: {
    obtenerVacacionesDisponibles () {
      this.$http.post('/api/empleado/vacaciones-disponibles', { empleado: this.$route.params.id }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.vacacionesDisponibles = data.vacaciones_disponibles))
    },
    createVacacion () {
      const vacacionObject = {
        fecha_inicial: moment(this.fechaInicial).format('YYYY-MM-DD'),
        fecha_final: moment(this.fechaFinal).format('YYYY-MM-DD'),
        empleadoId: this.$route.params.id
      }
      const dateInicial = moment(vacacionObject.fecha_inicial)
      const datefinal = moment(vacacionObject.fecha_final)
      const diasVacaciones = datefinal.diff(dateInicial, 'days') + 1
      if (diasVacaciones < 1) {
        this.errorCaptura = true
        setTimeout(() => (this.errorCaptura = false), 5000)
        return
      }
      this.$http.post('/api/exp-vacaciones-solicitadas', vacacionObject, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => this.$router.push({ name: 'empleado-vacaciones', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }))
        .catch(() => {
          this.errorCaptura = true
          setTimeout(() => (this.errorCaptura = false), 5000)
        })
    }
  },
  mounted () {
    this.obtenerVacacionesDisponibles()
  }
}
</script>
