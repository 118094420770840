<template>
    <td><select v-model="itemSelected" @change="updateHorario"
    class="px-2 block appearance-none w-full whitespace-no-wrap bg-opacity-0 bg-gray-200 text-gray-700 text-center text-xs rounded leading-tight focus:outline-none cursor-pointer" :class="[
      (item === 'Baja')?'text-red-500': 'text-gray-900',
      (item === 'Descanso')?'text-green-500': 'text-gray-900',
      (item === 'Desc. Laborado')?'text-orange-500': 'text-gray-900',
      (item === 'Incapacidad')?'text-purple-500': 'text-gray-900',
      (item === 'Vacaciones')?'text-blue-500': 'text-gray-900',
      (item === 'Falta')?'text-red-500': 'text-gray-900',
      (item === 'Permiso')?'text-indigo-500': 'text-gray-900',
    ]">
        <option>Laborado</option>
        <option>Descanso</option>
        <option>Desc. Laborado</option>
        <option>Baja</option>
        <option>Incapacidad</option>
        <option>Vacaciones</option>
        <option>Falta</option>
        <option>Permiso</option>
      </select>
    </td>
</template>

<script>
export default {
  name: 'ItemHorarioRow',
  props: {
    item: String,
    empleado: Number,
    fecha: String
  },
  data () {
    return {
      itemSelected: this.item
    }
  },
  methods: {
    updateHorario () {
      this.$emit('onUpdateHorario', this.empleado, this.fecha, this.itemSelected)
    }
  }
}
</script>
