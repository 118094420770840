<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="flex-1 min-w-0">
        <h4 class="font-semibold text-xl text-gray-800">Listado de empleados</h4>
      </div>
      <div class="flex mr-2">
        <label class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Sucursal</label>
        <div class="relative mr-4">
          <select v-model="sucursal" @change="changeSucursal" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 text-sm py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
            <option :value="0">-</option>
            <option v-for="(item, key) in sucursales" :value="item.id" :key="key">{{item.nombre}}</option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
          </div>
        </div>
      </div>
      <div class="flex">
        <input v-model="searchName" @keyup="search" class="text-sm leading-5 border rounded px-4 py-2 w-56" placeholder="Buscar">
      </div>
    </div>
    <div class="flex flex-col" v-if="empleados.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden sm:rounded">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"># Empleado</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">RFC</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">NSS</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Sucursal</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Fecha Ingreso</th>
                  <th class="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <ItemEmpleado v-for="(empleado, key) in empleados" :empleado="empleado" :key="key" class="bg-white odd:bg-gray-100" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemEmpleado from './ItemEmpleado'
export default {
  name: 'ListEmpleados',
  props: {
    empleados: Array,
    empleadoSearch: String,
    sucursales: Array,
    sucursalSelected: Number
  },
  watch: {
    sucursalSelected (newVal, oldVal) {
      this.sucursal = newVal
    }
  },
  components: {
    ItemEmpleado
  },
  data () {
    return {
      searchName: this.empleadoSearch,
      sucursal: this.sucursalSelected
    }
  },
  methods: {
    search () {
      this.$emit('onSearchEmpleado', this.searchName)
      this.$emit('onChangeSucursal', 0)
    },
    changeSucursal () {
      this.searchName = ''
      this.$emit('onSearchEmpleado', '')
      this.$emit('onChangeSucursal', this.sucursal)
    }
  }
}
</script>
