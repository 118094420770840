<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <router-link to="/horario" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-6">
        <h4 class="font-semibold text-xl text-gray-800">{{ empleadoNombre }}</h4>
        <span class="block text-xs text-gray-500" v-if="semanaId > 0">Semana: {{ semana.semana }} ({{ semana.fecha_inicial | moment("DD MMM YYYY") }} - {{ semana.fecha_final | moment("DD MMM YYYY") }})</span>
      </div>
    </div>
    <ListHorarioEmpleado :registroHorarioEmpleado="horarioEmpleado" @onUpdateHorarioEmpleadoConcepto="updateHorarioEmpleadoConcepto" @onUpdateHorarioEmpleadoSucursal="updateHorarioEmpleadoSucursal" class="mb-6"/>
  </div>
</template>

<script>
import authClient from '../utils/auth'
import ListHorarioEmpleado from '@/components/ListHorarioEmpleado'
export default {
  name: 'HorarioDetalleEmpleado',
  components: {
    ListHorarioEmpleado
  },
  data () {
    return {
      empleadoId: this.$route.params.empleadoId,
      empleadoNombre: this.$route.params.empleado,
      semanaId: this.$route.params.semanaId || 0,
      semana: {
        id: 0,
        periodo: 2022,
        semana: 0,
        fecha_inicial: '2022-01-01',
        fecha_final: '2022-01-01'
      },
      horarioEmpleado: []
    }
  },
  methods: {
    updateHorarioEmpleadoConcepto (registro, concepto) {
      this.$http.post('/api/turno-horario/actualiza-concepto', { registro, concepto }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => this.consultaHorarioEmpleado())
    },
    updateHorarioEmpleadoSucursal (registro, sucursal) {
      this.$http.post('/api/turno-horario/actualiza-sucursal', { registro, sucursal }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => this.consultaHorarioEmpleado())
    },
    obtenerSemana () {
      this.$http.get(`/api/semanas/${this.semanaId}`)
        .then(({ data }) => (this.semana = data))
    },
    consultaHorarioEmpleado () {
      this.$http.post('/api/turno-horario/obtener-empleado', { empleado: this.empleadoId, semana: this.semanaId }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.horarioEmpleado = data))
    }
  },
  mounted () {
    this.obtenerSemana()
    this.consultaHorarioEmpleado()
  }
}
</script>
