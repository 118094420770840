<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link :to="{ name: 'empleado-nomina', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-6 mb-10">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createAjusteNomina">
          <div class="shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-12">
                  <p class="uppercase text-base text-gray-700 font-medium tracking-wider">Ajuste Nomina</p>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-4">
                  <label class="block text-sm leading-5 text-gray-700">Semana Nomina</label>
                  <select v-model="semana" @change="obtenerTurno" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option v-for="(item, key) in semanas" :value="item.id" :key="key">Sem: {{item.semana}} ({{item.fecha_inicial | moment("DD MMM YYYY")}} - {{item.fecha_final | moment("DD MMM YYYY")}})</option>
                  </select>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-12">
                  <div class="shadow overflow-hidden sm:rounded">
                    <table class="min-w-full divide-y divide-gray-200">
                      <thead>
                        <tr>
                          <th class="px-6 py-3 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Jueves</th>
                          <th class="px-6 py-3 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Viernes</th>
                          <th class="px-6 py-3 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Sabado</th>
                          <th class="px-6 py-3 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Domingo</th>
                          <th class="px-6 py-3 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Lunes</th>
                          <th class="px-6 py-3 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Martes</th>
                          <th class="px-6 py-3 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Miercoles</th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200">
                        <tr>
                          <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900 text-center">{{ (turnoNomina.length > 0) ? turnoNomina[0].hora_entrada : '' }}</td>
                          <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900 text-center">{{ (turnoNomina.length > 0) ? turnoNomina[1].hora_entrada : '' }}</td>
                          <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900 text-center">{{ (turnoNomina.length > 0) ? turnoNomina[2].hora_entrada : '' }}</td>
                          <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900 text-center">{{ (turnoNomina.length > 0) ? turnoNomina[3].hora_entrada : '' }}</td>
                          <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900 text-center">{{ (turnoNomina.length > 0) ? turnoNomina[4].hora_entrada : '' }}</td>
                          <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900 text-center">{{ (turnoNomina.length > 0) ? turnoNomina[5].hora_entrada : '' }}</td>
                          <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900 text-center">{{ (turnoNomina.length > 0) ? turnoNomina[6].hora_entrada : '' }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-2">
                  <label class="block text-sm leading-5 text-gray-700">Dias Laborados</label>
                  <input v-model="laborado" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none" placeholder="0">
                </div>
                <div class="col-span-2">
                  <label class="block text-sm leading-5 text-gray-700">Dias Puntualidad</label>
                  <input v-model="puntualidad" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none" placeholder="0">
                </div>
                <div class="col-span-2">
                  <label class="block text-sm leading-5 text-gray-700">Dias Descanso</label>
                  <input v-model="descanso" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none" placeholder="0">
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Prima Dominical</label>
                  <select v-model="prima" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Bono Asistencia</label>
                  <select v-model="bono" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-6">
                  <label class="block text-sm leading-5 text-gray-700">Motivo</label>
                  <textarea v-model="motivo" rows="3" size="200" maxlength="200" class="mt-1 form-textarea block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none resize-none"></textarea>
                </div>
              </div>
              <button class="py-1 px-4 border border-transparent text-sm leading-5 rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none">
                Registrar
              </button>
              <span v-show="errorCaptura" class="text-red-600 text-sm ml-6">Error al registrar ajuste de nomina, verificar datos.</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
export default {
  name: 'NuevoNomina',
  data () {
    return {
      laborado: '',
      puntualidad: '',
      descanso: '',
      bono: true,
      prima: true,
      motivo: '',
      semana: 1,
      semanas: [],
      errorCaptura: false,
      turnoNomina: []
    }
  },
  methods: {
    obtenerSemanas () {
      this.$http.post('/api/semanas/semanas-activas')
        .then(({ data }) => {
          if (data.length > 0) {
            this.semanas = data.reverse()
            this.semana = data[0].id
            this.obtenerTurno()
          }
        })
    },
    obtenerTurno () {
      this.$http.post('/api/turno-laborado/empleado-semana', { semanaId: this.semana, empleadoId: this.$route.params.id }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.turnoNomina = data))
    },
    createAjusteNomina () {
      const ajusteNominaObject = {
        dias_laborados: this.laborado,
        dias_puntualidad: this.puntualidad,
        dias_descanso: this.descanso,
        bono_asistencia: this.bono,
        prima_dominical: this.prima,
        motivo: this.motivo,
        empleadoId: this.$route.params.id,
        semanaId: this.semana
      }
      this.$http.post('/api/turno-ajustes', ajusteNominaObject, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => this.$router.push({ name: 'empleado-nomina', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }))
        .catch(() => {
          this.errorCaptura = true
          setTimeout(() => (this.errorCaptura = false), 5000)
        })
    }
  },
  mounted () {
    this.obtenerSemanas()
  }
}
</script>
