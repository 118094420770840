<template>
  <div class="relative inline-block text-left">
    <div>
      <span class="rounded shadow-sm">
        <button @click="mostrarMenu" type="button" class="inline-flex justify-center w-full rounded border border-gray-300 px-4 py-1 bg-white text-xs leading-5  text-gray-700 hover:text-gray-500 focus:outline-none active:bg-gray-50 active:text-gray-800">
          Expediente Laboral
          <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
          </svg>
        </button>
      </span>
    </div>
    <div v-show="menuVisible" class="origin-top-right absolute right-0 mt-2 w-56 rounded shadow-lg">
      <div class="rounded bg-white shadow-xs" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <div class="py-1">
          <router-link :to="{ name: 'empleado', params: { id: empleadoId }}" class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Datos del Empleado</router-link>
          <router-link :to="{ name: 'empleado-contrato', params: { id: empleadoId, nombreempleado: registroEmpleado }}" class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Contrato Laboral</router-link>
          <router-link :to="{ name: 'empleado-tarjeta', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Cuenta Bancaria</router-link>
        </div>
        <div class="border-t border-gray-100"></div>
        <div class="py-1">
          <router-link :to="{ name: 'empleado-descuentos', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Descuentos</router-link>
          <router-link :to="{ name: 'empleado-bonos', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Bonos</router-link>
          <router-link :to="{ name: 'empleado-prestamos', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Prestamos</router-link>
          <router-link :to="{ name: 'empleado-primavacacional', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Prima Vacacional</router-link>
          <router-link :to="{ name: 'empleado-vacaciones', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Vacaciones</router-link>
          <router-link :to="{ name: 'empleado-puestos', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Puestos</router-link>
          <router-link :to="{ name: 'empleado-sueldos', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Sueldos</router-link>
          <router-link :to="{ name: 'empleado-sucursales', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Sucursal Asignada</router-link>
        </div>
        <div class="border-t border-gray-100"></div>
        <div class="py-1">
          <router-link :to="{ name: 'empleado-nomina', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Nomina</router-link>
        </div>
        <div class="border-t border-gray-100"></div>
        <div class="py-1">
          <router-link :to="{ name: 'empleado-baja', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Baja</router-link>
          <router-link :to="{ name: 'empleado-finiquito', params: { id: empleadoId, nombreempleado: registroEmpleado }}"  class="block px-4 py-2 text-xs leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900" role="menuitem">Finiquito</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavExpediente',
  props: {
    empleadoId: Number,
    registroEmpleado: String
  },
  data () {
    return {
      menuVisible: false
    }
  },
  methods: {
    mostrarMenu () {
      this.menuVisible = !this.menuVisible
    }
  }
}
</script>
