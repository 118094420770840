<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Descuentos</h4>
      </div>
      <div class="col-span-3 justify-self-end">
        <NavExpediente :registroEmpleado="this.$route.params.nombreempleado" :empleadoId="this.$route.params.id" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-8">
      <div class="col-span-3">
        <router-link :to="{ name: 'descuento-nuevo', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="bg-blue-500 hover:bg-blue-600 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-auto">Agregar Descuento</router-link>
      </div>
    </div>
    <ListDescuentos :descuentos="descuentos" class="mb-6"/>
    <Pagination :count="descuentosCount" :activePage="activePage" @onChangePage="cambiarPaginacion" />
  </div>
</template>

<script>
import NavExpediente from '@/components/NavExpediente'
import authClient from '@/utils/auth'
import ListDescuentos from '@/components/ListDescuentos'
import Pagination from '@/components/Pagination'
export default {
  name: 'EmpleadoDescuentos',
  components: {
    NavExpediente,
    ListDescuentos,
    Pagination
  },
  data () {
    return {
      descuentos: [],
      descuentosCount: 0,
      activePage: 0
    }
  },
  methods: {
    obtenerDescuentos () {
      this.$http.post('/api/exp-descuento/descuentos-empleado', { empleado: this.$route.params.id, page: this.activePage }, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      }).then(({ data }) => (this.descuentos = data))
      this.$http.post('/api/exp-descuento/descuentos-empleado-count', { empleado: this.$route.params.id }, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      }).then(({ data }) => (this.descuentosCount = data))
    },
    cambiarPaginacion (pageSelected) {
      this.activePage = pageSelected
      this.obtenerDescuentos()
    }
  },
  mounted () {
    this.obtenerDescuentos()
  }
}
</script>
