<template>
  <div class="bg-white px-4 py-3 flex items-center justify-center border-t border-gray-200 sm:px-6">
    <nav class="relative z-0 inline-flex shadow-sm">
      <button @click="previousPage" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Previous">
        <!-- Heroicon name: chevron-left -->
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
        </svg>
      </button>
      <button v-for="index in count" :key="index" @click="changePage(index)" v-bind:class="{ 'bg-gray-200': index === (activePage + 1) }" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
        {{ index }}
      </button>
      <button @click="nextPage" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Next">
        <!-- Heroicon name: chevron-right -->
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
        </svg>
      </button>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    count: Number,
    activePage: Number
  },
  methods: {
    previousPage () {
      if (this.activePage > 0) {
        this.$emit('onChangePage', this.activePage - 1)
      }
    },
    nextPage () {
      if (this.activePage < this.count - 1) {
        this.$emit('onChangePage', this.activePage + 1)
      }
    },
    changePage (index) {
      this.$emit('onChangePage', index - 1)
    }
  }
}
</script>
