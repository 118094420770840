<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link :to="{ name: 'empleado-sucursales', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-6 mb-10">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createSucursal">
          <div class="shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-12">
                  <p class="uppercase text-base text-gray-700 font-medium tracking-wider">Actualizar Sucursal</p>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Sucursal</label>
                  <select v-model="sucursal" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option v-for="(item, key) in sucursales" :value="item.id" :key="key">{{item.nombre}}</option>
                  </select>
                </div>
              </div>
              <button class="py-1 px-4 border border-transparent text-sm leading-5 rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none">
                Registrar Sucursal
              </button>
              <span v-show="errorCaptura" class="text-red-600 text-sm ml-6">Error al registrar sucursal, verificar datos.</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
export default {
  name: 'NuevoSucursal',
  data () {
    return {
      empresaSeleccionada: this.$store.getters.empresaSeleccionada,
      sucursales: [],
      sucursal: 0,
      errorCaptura: false
    }
  },
  methods: {
    obtenerSucursales () {
      this.$http.post('/api/sucursal/sucursales-empresa', { empresa: this.empresaSeleccionada }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          this.sucursales = data
          if (data.length > 0) {
            this.obtenerSucursal()
          }
        })
    },
    obtenerSucursal () {
      this.$http.post('/api/empleado/sucursal-empleado', { empleado: this.$route.params.id }, {
        headers: { Authorization: authClient.getAuthToken() }
      }).then(({ data }) => (this.sucursal = data.sucursalId))
    },
    createSucursal () {
      const sucursalObject = {
        sucursalId: this.sucursal,
        empleadoId: this.$route.params.id
      }
      this.$http.post('/api/exp-sucursal/registrar-sucursal', sucursalObject, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => {
          this.$router.push({
            name: 'empleado-sucursales',
            params: {
              id: this.$route.params.id,
              nombreempleado: this.$route.params.nombreempleado
            }
          })
        })
        .catch(() => {
          this.errorCaptura = true
          setTimeout(() => (this.errorCaptura = false), 5000)
        })
    }
  },
  mounted () {
    this.obtenerSucursales()
  }
}
</script>
