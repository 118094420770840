<template>
  <tr>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.numero_empleado }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.nombre }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.puesto_referencia }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.semana[Object.keys(registro.semana)[0]] }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.semana[Object.keys(registro.semana)[1]] }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.semana[Object.keys(registro.semana)[2]] }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.semana[Object.keys(registro.semana)[3]] }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.semana[Object.keys(registro.semana)[4]] }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.semana[Object.keys(registro.semana)[5]] }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.semana[Object.keys(registro.semana)[6]] }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ItemTurnoDetalle',
  props: {
    registro: Object
  }
}
</script>
