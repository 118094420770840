<template>
  <tr>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ bono.categoria }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ bono.monto | currency }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ (bono.activo) ? 'Si' : 'No' }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ (bono.pago === 1) ? 'Recurrente' : 'Unico' }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ bono.motivo }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ (bono.pago === 1) ? '' : `${bono.semana.semana} - ${ bono.semana.periodo }` }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">
      <button @click="updateStatus(bono.id, bono.activo)" class="text-red-600 hover:text-red-900 focus:outline-none">{{ (bono.activo) ? 'Desactivar' : 'Activar' }}</button>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ItemBonos',
  props: {
    bono: Object
  },
  methods: {
    updateStatus (bonoId, bonoStatus) {
      this.$emit('onBonosStatus', bonoId, bonoStatus)
    }
  }
}
</script>
