<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
    </div>
    <div class="flex items-center mb-6">
      <h4 class="font-semibold text-xl text-gray-800">Horarios</h4>
    </div>
    <div class="flex items-center mb-6">
      <label for="sucursal" class="block text-sm leading-5 font-medium text-gray-700 mr-4">Sucursal</label>
      <div class="relative mr-4">
        <select v-model="sucursal" @change="cambiarSucursal" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 text-sm py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option v-for="(item, key) in sucursales" :value="item.id" :key="key">{{item.nombre}}</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
      <label for="semana" class="block text-sm leading-5 font-medium text-gray-700 mr-4">Semana</label>
      <div class="relative mr-4">
        <select v-model="semana" @change="cambiarSemana" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 text-sm py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option v-for="(item, key) in semanas" :value="item.id" :key="key">Sem: {{item.semana}} ({{item.fecha_inicial | moment("DD MMM YYYY")}} - {{item.fecha_final | moment("DD MMM YYYY")}})</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
    <ListHorario :registroHorarioSucursal="registroHorarioSucursal" :registroHorarioTotal="registroHorarioTotal" @onUpdateHorario="updateHorario" class="mb-6"/>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import ListHorario from '@/components/ListHorario'
export default {
  name: 'Horario',
  components: {
    ListHorario
  },
  data () {
    return {
      sucursal: 0,
      sucursales: [],
      semana: 0,
      semanas: [],
      empresaSeleccionada: this.$store.getters.empresaSeleccionada,
      registroHorarioSucursal: [],
      registroHorarioTotal: []
    }
  },
  methods: {
    obtenerSucursales () {
      this.$http.post('/api/sucursal/sucursales-empresa', { empresa: this.empresaSeleccionada }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.sucursales = data
            this.sucursal = data[0].id
            this.obtenerSemanas()
          }
        })
    },
    obtenerSemanas () {
      this.$http.post('/api/semanas/semanas-horario')
        .then(({ data }) => {
          if (data.length > 0) {
            this.semanas = data.reverse()
            this.semana = data[1].id
            this.consultaHorario()
          }
        })
    },
    cambiarSucursal () {
      this.registroHorarioSucursal = []
      this.registroHorarioTotal = []
      this.consultaHorario()
    },
    cambiarSemana () {
      this.registroHorarioSucursal = []
      this.registroHorarioTotal = []
      this.consultaHorario()
    },
    consultaHorario () {
      this.$http.post('/api/turno-horario/obtener', { sucursal: this.sucursal, semana: this.semana }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.registroHorarioSucursal = data))
      this.$http.post('/api/turno-horario/obtener-total', { sucursal: this.sucursal, semana: this.semana }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.registroHorarioTotal = data))
    },
    updateHorario (empleado, fecha, concepto) {
      this.$http.post('/api/turno-horario/actualizar', { empleado, fecha, concepto }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => this.consultaHorario())
    }
  },
  mounted () {
    this.obtenerSucursales()
  }
}
</script>
