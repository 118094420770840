<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a empleados activos</router-link>
    </div>
    <div class="flex flex-row mb-6">
      <router-link to="/empleado-externo-nuevo" class="bg-blue-600 hover:bg-blue-700 text-white text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline ml-auto">Nuevo Externo</router-link>
    </div>
    <ListExternos :empleados="empleados" @onSearchEmpleado="buscarEmpleado" :empleadoSearch="empleadoSearch" class="mb-6"/>
    <Pagination :count="empleadosCount" :activePage="activePage" @onChangePage="cambiarPaginacion" />
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import ListExternos from '@/components/ListExternos'
import Pagination from '@/components/Pagination'
export default {
  name: 'Externos',
  components: {
    ListExternos,
    Pagination
  },
  data () {
    return {
      empresas: [],
      empresaSeleccionada: this.$store.getters.empresaSeleccionada,
      empleados: [],
      empleadosCount: 0,
      activePage: 0,
      empleadoSearch: '',
      sucursales: [],
      sucursal: []
    }
  },
  methods: {
    obtenerSucursales () {
      this.sucursal = []
      this.$http.post('/api/sucursal/sucursales-empresa', { empresa: this.empresaSeleccionada }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.sucursales = data
            data.forEach(element => {
              this.sucursal.push(element.id)
            })
            this.listaEmpleadosEmpresa()
          }
        })
    },
    listaEmpleadosEmpresa () {
      this.$http.post('/api/empleado-externo/empleados-empresa', { empresa: this.empresaSeleccionada, page: this.activePage, empleadoSearch: this.empleadoSearch, empleadoStatus: true, sucursal: this.sucursal }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.empleados = data))
      this.$http.post('/api/empleado-externo/empleados-empresa-count', { empresa: this.empresaSeleccionada, empleadoSearch: this.empleadoSearch, empleadoStatus: true, sucursal: this.sucursal }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.empleadosCount = data))
    },
    cambiarPaginacion (pageSelected) {
      this.activePage = pageSelected
      this.listaEmpleadosEmpresa()
    },
    buscarEmpleado (empleadoSearch) {
      this.empleadoSearch = empleadoSearch
      this.activePage = 0
      this.listaEmpleadosEmpresa()
    }
  },
  mounted () {
    this.obtenerSucursales()
  }
}
</script>
