<template>
  <tr>
    <td class="px-4 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.fecha | moment("DD MMMM YYYY - dddd") }}</td>
    <ItemHorarioEmpleadoConcepto :item="registro" @onUpdateHorarioEmpleadoConcepto="updateHorarioEmpleadoConcepto" />
    <ItemHorarioEmpleadoSucursal :item="registro" @onUpdateHorarioEmpleadoSucursal="updateHorarioEmpleadoSucursal" />
  </tr>
</template>

<script>
import ItemHorarioEmpleadoConcepto from './ItemHorarioEmpleadoConcepto'
import ItemHorarioEmpleadoSucursal from './ItemHorarioEmpleadoSucursal'
export default {
  name: 'ItemHorarioEmpleado',
  components: {
    ItemHorarioEmpleadoConcepto,
    ItemHorarioEmpleadoSucursal
  },
  props: {
    registro: Object
  },
  methods: {
    updateHorarioEmpleadoConcepto (registro, concepto) {
      this.$emit('onUpdateHorarioEmpleadoConcepto', registro, concepto)
    },
    updateHorarioEmpleadoSucursal (registro, sucursal) {
      this.$emit('onUpdateHorarioEmpleadoSucursal', registro, sucursal)
    }
  }
}
</script>
