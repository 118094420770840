<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <router-link to="/turno" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Solicitudes: Correcion de horario entrada</h4>
      </div>
    </div>
    <ListTurnoAjustes :solicitudes="solicitudes" @onConsultaRevisada="consultaRevisada" class="mb-6"/>
  </div>
</template>

<script>
import authClient from '../utils/auth'
import ListTurnoAjustes from '@/components/ListTurnoAjustes'
export default {
  name: 'TurnoAjustes',
  components: {
    ListTurnoAjustes
  },
  data () {
    return {
      empresaSeleccionada: this.$store.getters.empresaSeleccionada,
      solicitudes: []
    }
  },
  methods: {
    consultaSolicitudes () {
      this.$http.post('api/turno-laborado-ajuste/solicitudes', { empresa: this.empresaSeleccionada }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.solicitudes = data))
    },
    consultaRevisada (consultaId, consultaStatus) {
      this.$http.post('api/turno-laborado-ajuste/solicitud-revisada', { solicitudId: consultaId, solictudStatus: consultaStatus }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => this.consultaSolicitudes())
        .catch(() => this.$router.push('/turno'))
    }
  },
  mounted () {
    this.consultaSolicitudes()
  }
}
</script>
