<template>
  <div class="flex flex-col" v-if="registroHorarioEmpleado.length > 0">
    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden sm:rounded">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th class="px-4 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
                <th class="px-2 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Concepto</th>
                <th class="px-2 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Sucursal</th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <ItemHorarioEmpleado v-for="(registro, key) in registroHorarioEmpleado" :registro="registro" :key="key" @onUpdateHorarioEmpleadoConcepto="updateHorarioEmpleadoConcepto" @onUpdateHorarioEmpleadoSucursal="updateHorarioEmpleadoSucursal" class="bg-white odd:bg-gray-100" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemHorarioEmpleado from './ItemHorarioEmpleado'
export default {
  name: 'ListHorarioEmpleado',
  props: {
    registroHorarioEmpleado: Array
  },
  components: {
    ItemHorarioEmpleado
  },
  methods: {
    updateHorarioEmpleadoConcepto (registro, concepto) {
      this.$emit('onUpdateHorarioEmpleadoConcepto', registro, concepto)
    },
    updateHorarioEmpleadoSucursal (registro, sucursal) {
      this.$emit('onUpdateHorarioEmpleadoSucursal', registro, sucursal)
    }
  }
}
</script>
