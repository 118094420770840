<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/nomina" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800">Nomina {{ semana.semana }} - {{ semana.periodo }}</h4>
        <span class="text-xs text-gray-500">{{ semana.fecha_inicial | moment("DD MMMM YYYY") }} - {{ semana.fecha_final | moment("DD MMMM YYYY") }}</span>
      </div>
      <div class="col-span-3">
        <select v-model="sucursal" @change="changeSucursal" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
          <option v-for="(item, key) in sucursales" :value="item.id" :key="key">{{item.nombre}}</option>
        </select>
      </div>
    </div>
    <ListIncidencias :registros="registros" class="mb-6"/>
  </div>
</template>

<script>
import ListIncidencias from '@/components/ListIncidencias'
import authClient from '@/utils/auth'
export default {
  name: 'NominaIncidencias',
  components: {
    ListIncidencias
  },
  data () {
    return {
      registros: [],
      semanaId: this.$route.params.id,
      empresa: this.$store.getters.empresaSeleccionada,
      sucursales: [],
      sucursal: 0,
      semana: {
        semana: 1,
        periodo: 2020,
        fecha_inicial: '2020-01-01',
        fecha_final: '2020-01-01'
      }
    }
  },
  methods: {
    obtenerRegistros () {
      this.$http.post('/api/turno-nomina/incidencias-semana', {
        semanaId: this.semanaId, empresaId: this.empresa, sucursalId: this.sucursal
      }, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.registros = data
          }
        })
    },
    obtenerSucursales () {
      this.$http.post('/api/sucursal/sucursales-empresa', { empresa: this.empresa }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.sucursales = data
            this.sucursal = data[0].id
            this.obtenerRegistros()
          }
        })
    },
    obtenerSemana () {
      this.$http.get(`/api/semanas/${this.semanaId}`)
        .then(({ data }) => (this.semana = data))
    },
    changeSucursal () {
      this.obtenerRegistros()
    }
  },
  mounted () {
    this.obtenerSemana()
    this.obtenerSucursales()
  }
}
</script>
