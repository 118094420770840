<template>
  <div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden sm:rounded">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Codigo</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Empleado</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Dep.</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Sueldo Diario</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Faltas</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Dias de Vacaciones</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Prima Dominical</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Prima Vacacional</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Desc. Multa</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Desc. Corte</th>
                  <th class="px-2 py-2 text-left text-xs leading-4 font-medium text-gray-500 uppercase">Desc. Inven.</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <ItemIncidencias v-for="(registro, key) in registros" :registro="registro" :key="key" class="bg-white odd:bg-gray-100" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemIncidencias from './ItemIncidencias'
export default {
  name: 'ListIncidencias',
  props: {
    registros: Array
  },
  components: {
    ItemIncidencias
  }
}
</script>
