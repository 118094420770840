<template>
  <div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden sm:rounded">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Sucursal</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Clave</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <ItemSucursales v-for="(sucursal, key) in sucursales" :sucursal="sucursal" :key="key" class="bg-white odd:bg-gray-100" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemSucursales from './ItemSucursales'
export default {
  name: 'ListSucursales',
  props: {
    sucursales: Array
  },
  components: {
    ItemSucursales
  }
}
</script>
