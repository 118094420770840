<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link :to="{ name: 'empleado-puestos', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-6 mb-10">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createPuesto">
          <div class="shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-12">
                  <p class="uppercase text-base text-gray-700 font-medium tracking-wider">Actualizar Puesto</p>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Fecha</label>
                  <datepicker v-model="fechaPuesto" :language="es" :format="format"></datepicker>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Puesto Referencia</label>
                  <select v-model="puestoReferencia" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option>Encargado A</option>
                    <option>Encargado B</option>
                    <option>Ayudante General</option>
                    <option>Entrenador</option>
                    <option>Cajero</option>
                    <option>Repartidor</option>
                  </select>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Puesto Contrato</label>
                  <select v-model="puestoContrato" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option>Encargado A</option>
                    <option>Encargado B</option>
                    <option>Ayudante general con función de preparador de alimentos y/o cajero</option>
                    <option>Ayudante general con función de entrenador en cocina y cajas</option>
                    <option>Repartidor de alimentos y bebidas</option>
                  </select>
                </div>
              </div>
              <button class="py-1 px-4 border border-transparent text-sm leading-5 rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none">
                Registrar Puesto
              </button>
              <span v-show="errorCaptura" class="text-red-600 text-sm ml-6">Error al registrar puesto, verificar datos.</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/src/locale'
import moment from 'moment-timezone'
import authClient from '@/utils/auth'
export default {
  name: 'NuevoPuesto',
  components: {
    Datepicker
  },
  data () {
    return {
      es: es,
      format: 'd MMMM yyyy',
      fechaPuesto: '',
      puestoReferencia: 'Encargado A',
      puestoContrato: 'Encargado A',
      errorCaptura: false
    }
  },
  methods: {
    obtenerPuesto () {
      this.$http.post('/api/empleado/puesto-empleado', { empleado: this.$route.params.id }, {
        headers: { Authorization: authClient.getAuthToken() }
      }).then(({ data }) => {
        this.puestoReferencia = data.puesto_referencia
        this.puestoContrato = data.puesto_contrato
      })
    },
    createPuesto () {
      let fecha = moment().format('YYYY-MM-DD')

      if (moment(this.fechaPuesto).isValid()) {
        fecha = moment().format('YYYY-MM-DD')
      }

      const puestoObject = {
        fecha,
        puesto_referencia: this.puestoReferencia,
        puesto_contrato: this.puestoContrato,
        empleadoId: this.$route.params.id
      }
      this.$http.post('/api/exp-puesto/registrar-puesto', puestoObject, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => {
          this.$router.push({
            name: 'empleado-puestos',
            params: {
              id: this.$route.params.id,
              nombreempleado: this.$route.params.nombreempleado
            }
          })
        })
        .catch(() => {
          this.errorCaptura = true
          setTimeout(() => (this.errorCaptura = false), 5000)
        })
    }
  },
  mounted () {
    this.obtenerPuesto()
  }
}
</script>
