<template>
  <div>
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden sm:rounded">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Codigo</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Empleado</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Nomina fiscal</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Deducciones</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Sueldo</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Bono puntualidad</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Bono asistencia</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Bono antiguedad</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Bono entrenador</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Bono otros</th>
                  <th class="px-1 py-2 text-left text-xs leading-4 font-normal text-gray-500 uppercase">Deposito</th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <ItemNominaPension v-for="(registro, key) in registros" :registro="registro" :key="key" class="bg-white odd:bg-gray-100" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemNominaPension from './ItemNominaPension'
export default {
  name: 'ListNominaPension',
  props: {
    registros: Array
  },
  components: {
    ItemNominaPension
  }
}
</script>
