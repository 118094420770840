<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/nomina" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800">Nomina {{ semana.semana }} - {{ semana.periodo }}</h4>
        <span class="text-xs text-gray-500">{{ semana.fecha_inicial | moment("DD MMMM YYYY") }} - {{ semana.fecha_final | moment("DD MMMM YYYY") }}</span>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-2">
      <div class="col-span-9">
        <p class="font-medium text-lg text-gray-600">Nomina Pension</p>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-9">
        <button :disabled="estatusDescargaDispersionFlag" class="bg-gray-700 hover:bg-gray-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-auto" @click.prevent="downloadDispersion">{{ estatusDescargaDispersion }}</button>
      </div>
      <div class="col-span-3">
        <select v-model="sucursal" @change="changeSucursal" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
          <option v-for="(item, key) in sucursales" :value="item.id" :key="key">{{item.nombre}}</option>
        </select>
      </div>
    </div>
    <ListNominaPension :registros="registros" class="mb-6" />
  </div>
</template>

<script>
import ListNominaPension from '@/components/ListNominaPension'
import authClient from '@/utils/auth'
export default {
  name: 'NominaPension',
  components: {
    ListNominaPension
  },
  data () {
    return {
      semanaId: this.$route.params.id,
      empresa: this.$store.getters.empresaSeleccionada,
      semana: {
        semana: 1,
        periodo: 2020,
        fecha_inicial: '2020-01-01',
        fecha_final: '2020-01-01'
      },
      registros: [],
      sucursales: [],
      sucursal: 0,
      estatusDescargaDispersion: 'Descargar Solicitud de Dispersion',
      estatusDescargaDispersionFlag: false,
      nombreEmpresa: ''
    }
  },
  methods: {
    obtenerSucursales () {
      this.$http.post('/api/sucursal/sucursales-empresa', { empresa: this.empresa }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.sucursales = data
            this.sucursal = data[0].id
            this.obtenerRegistros()
          }
        })
    },
    obtenerSemana () {
      this.$http.get(`/api/semanas/${this.semanaId}`)
        .then(({ data }) => (this.semana = data))
    },
    obtenerRegistros () {
      this.$http.post('/api/nomina-pension/registros-nomina', { semana: this.semanaId, empresa: this.empresa, sucursal: this.sucursal }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.registros = data))
        .catch(() => {})
    },
    changeSucursal () {
      this.obtenerRegistros()
    },
    downloadDispersion () {
      this.estatusDescargaDispersion = 'Descargando...'
      this.estatusDescargaDispersionFlag = true
      this.$http.post('/api/nomina-pension/solicitud-dispersion-xls', { semanaId: this.semanaId, empresaId: this.$store.getters.empresaSeleccionada }, {
        responseType: 'blob',
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `SOLICITUD DE DISPERSIÓN ${this.nombreEmpresa} semana ${this.semana.semana}`
          link.click()
          URL.revokeObjectURL(link.href)
          this.estatusDescargaDispersion = 'Descargar Solicitud de Dispersion'
          this.estatusDescargaDispersionFlag = false
        })
    }
  },
  mounted () {
    this.obtenerSemana()
    this.obtenerSucursales()
    if (this.nombreEmpresa === '') {
      this.$http.get(`/api/empresa/${this.$store.getters.empresaSeleccionada}`)
        .then(({ data }) => (this.nombreEmpresa = data.nombre))
    }
  }
}
</script>
