<template>
  <nav class="flex items-center justify-between flex-wrap bg-red-600 p-4">
    <div class="flex items-center flex-shrink-0 text-white font-light mr-6">
      <span>Nomina</span>
    </div>
    <div class="flex-grow flex items-center w-auto">
      <div class="text-sm flex-grow text-white font-light">{{ nombreEmpresa }}</div>
      <div>
        <button v-if="this.$store.getters.isAuthenticated" @click="logout" class="inline-block text-sm px-4 py-2 leading-none border rounded text-white border-white hover:border-transparent hover:text-red-500 hover:bg-white lg:mt-0 font-light">Salir</button>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'Nav',
  data () {
    return {
      nombreEmpresa: ''
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('AUTH_LOGOUT')
        .then(() => this.$router.push('/login'))
    }
  },
  computed: mapState(['empresa']),
  watch: {
    empresa (newValue, oldValue) {
      this.$http.get(`/api/empresa/${newValue}`)
        .then(({ data }) => (this.nombreEmpresa = data.nombre))
    }
  },
  mounted () {
    if (this.nombreEmpresa === '') {
      this.$http.get(`/api/empresa/${this.$store.getters.empresaSeleccionada}`)
        .then(({ data }) => (this.nombreEmpresa = data.nombre))
    }
  }
}
</script>
