<template>
  <tr>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ puesto.fecha | moment("DD MMMM YYYY") }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ puesto.puesto_referencia }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ puesto.puesto_contrato }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ItemPuestos',
  props: {
    puesto: Object
  }
}
</script>
