<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden sm:rounded">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th class="px-4 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
                <th class="px-4 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Hora</th>
                <th class="px-4 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Empleado</th>
                <th class="px-4 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Sucursal</th>
                <th class="px-4 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Motivo</th>
                <th class="px-4 py-3"></th>
                <th class="px-4 py-3"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <ItemTurnoAjustes v-for="(solicitud, key) in solicitudes" :solicitud="solicitud" @onConsultaRevisada="consultaRevisada" :key="key" class="bg-white odd:bg-gray-100" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTurnoAjustes from './ItemTurnoAjustes'
export default {
  name: 'ListTurnoAjustes',
  props: {
    solicitudes: Array
  },
  components: {
    ItemTurnoAjustes
  },
  methods: {
    consultaRevisada (consultaId, consultaStatus) {
      this.$emit('onConsultaRevisada', consultaId, consultaStatus)
    }
  }
}
</script>
