<template>
  <tr>
    <td class="px-4 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900">{{ registro.numero_empleado }}</td>
    <td class="px-2 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.colaborador }}</td>
    <ItemHorarioRow :item="registro.semana[Object.keys(registro.semana)[0]]" :fecha="Object.keys(registro.semana)[0]" :empleado="registro.empleadoId" @onUpdateHorario="updateHorario" />
    <ItemHorarioRow :item="registro.semana[Object.keys(registro.semana)[1]]" :fecha="Object.keys(registro.semana)[1]" :empleado="registro.empleadoId" @onUpdateHorario="updateHorario" />
    <ItemHorarioRow :item="registro.semana[Object.keys(registro.semana)[2]]" :fecha="Object.keys(registro.semana)[2]" :empleado="registro.empleadoId" @onUpdateHorario="updateHorario" />
    <ItemHorarioRow :item="registro.semana[Object.keys(registro.semana)[3]]" :fecha="Object.keys(registro.semana)[3]" :empleado="registro.empleadoId" @onUpdateHorario="updateHorario" />
    <ItemHorarioRow :item="registro.semana[Object.keys(registro.semana)[4]]" :fecha="Object.keys(registro.semana)[4]" :empleado="registro.empleadoId" @onUpdateHorario="updateHorario" />
    <ItemHorarioRow :item="registro.semana[Object.keys(registro.semana)[5]]" :fecha="Object.keys(registro.semana)[5]" :empleado="registro.empleadoId" @onUpdateHorario="updateHorario" />
    <ItemHorarioRow :item="registro.semana[Object.keys(registro.semana)[6]]" :fecha="Object.keys(registro.semana)[6]" :empleado="registro.empleadoId" @onUpdateHorario="updateHorario" />
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium">
      <router-link :to="{ name: 'horario-detalle-empleado', params: { empleadoId: registro.empleadoId, empleado: registro.colaborador, semanaId: registro.semanaId } }" class="text-red-600 hover:text-red-900">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
          <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z"/>
          <path d="M14 2v6h6M16 13H8M16 17H8M10 9H8"/>
        </svg>
      </router-link>
    </td>
  </tr>
</template>

<script>
import ItemHorarioRow from './ItemHorarioRow'
export default {
  name: 'ItemHorario',
  components: {
    ItemHorarioRow
  },
  props: {
    registro: Object
  },
  methods: {
    updateHorario (empleado, fecha, itemSelected) {
      this.$emit('onUpdateHorario', empleado, fecha, itemSelected)
    }
  }
}
</script>
