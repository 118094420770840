<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link :to="{ name: 'empleado-bonos', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-6 mb-10">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createBono">
          <div class="shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-12">
                  <p class="uppercase text-base text-gray-700 font-medium tracking-wider">Nuevo Bono</p>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Categoria</label>
                  <select v-model="categoria" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option>Asistencia</option>
                    <option>Puntualidad</option>
                    <option>Antiguedad</option>
                    <option>Entrenador</option>
                    <option>Transporte</option>
                    <option>Otro</option>
                  </select>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Monto</label>
                  <input v-model="monto" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none" placeholder="0.00">
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Pago</label>
                  <select v-model="pago" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option value="1">Recurrente</option>
                    <option value="2">Unico</option>
                  </select>
                </div>
                <div class="col-span-3" v-show="pago === '2'">
                  <label class="block text-sm leading-5 text-gray-700">Semana de Pago</label>
                  <select v-model="semana" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option v-for="(item, key) in semanas" :value="item.id" :key="key">Sem: {{item.semana}} ({{item.fecha_inicial | moment("DD MMM YYYY")}} - {{item.fecha_final | moment("DD MMM YYYY")}})</option>
                  </select>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-6">
                  <label class="block text-sm leading-5 text-gray-700">Motivo</label>
                  <textarea v-model="motivo" rows="3" size="200" maxlength="200" class="mt-1 form-textarea block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none resize-none"></textarea>
                </div>
              </div>
              <button class="py-1 px-4 border border-transparent text-sm leading-5 rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none">
                Registrar Bono
              </button>
              <span v-show="errorCaptura" class="text-red-600 text-sm ml-6">Error al registrar bono, verificar datos.</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
export default {
  name: 'NuevoBono',
  data () {
    return {
      categoria: 'Asistencia',
      pago: '1',
      monto: '',
      motivo: '',
      semana: 1,
      semanas: [],
      errorCaptura: false
    }
  },
  methods: {
    obtenerSemanas () {
      this.$http.post('/api/semanas/semanas-activas')
        .then(({ data }) => {
          if (data.length > 0) {
            this.semanas = data.reverse()
            this.semana = data[0].id
          }
        })
    },
    createBono () {
      const bonoObject = {
        tipo: this.tipoBono,
        categoria: this.categoria,
        monto: this.monto,
        motivo: this.motivo,
        pago: this.pago,
        empleadoId: this.$route.params.id,
        semanaId: this.semana
      }
      this.$http.post('/api/exp-bono', bonoObject, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => this.$router.push({ name: 'empleado-bonos', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }))
        .catch(() => {
          this.errorCaptura = true
          setTimeout(() => (this.errorCaptura = false), 5000)
        })
    }
  },
  computed: {
    tipoBono: {
      get () {
        let tipo = 1
        switch (this.categoria) {
          case 'Asistencia':
            tipo = 1
            break
          case 'Puntualidad':
            tipo = 2
            break
          case 'Antiguedad':
            tipo = 3
            break
          case 'Entrenador':
            tipo = 4
            break
          case 'Transporte':
            tipo = 5
            break
          case 'Otro':
            tipo = 5
            break
          default:
            break
        }
        return tipo
      }
    }
  },
  mounted () {
    this.obtenerSemanas()
  }
}
</script>
