<template>
  <div>
    <div class="flex justify-between mb-4">
      <div class="flex-1 min-w-0">
        <h4 class="font-semibold text-xl text-gray-800">Listado de Externos</h4>
      </div>
      <div class="flex">
        <input v-model="searchName" @keyup="search" class="text-sm leading-5 border rounded px-4 py-2 w-56" placeholder="Buscar">
      </div>
    </div>
    <div class="flex flex-col" v-if="empleados.length > 0">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden sm:rounded">
            <table class="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"># Empleado</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">CURP</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Sucursal</th>
                  <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Fecha Ingreso</th>
                  <th class="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200">
                <ItemEmpleadoExterno v-for="(empleado, key) in empleados" :empleado="empleado" :key="key" class="bg-white odd:bg-gray-100" />
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemEmpleadoExterno from './ItemEmpleadoExterno'
export default {
  name: 'ListBajas',
  props: {
    empleados: Array,
    empleadoSearch: String
  },
  watch: {
    empleadoSearch (newVal, oldVal) {
      if (newVal === '') {
        this.resetSearch()
      }
    }
  },
  components: {
    ItemEmpleadoExterno
  },
  data () {
    return {
      searchName: this.empleadoSearch
    }
  },
  methods: {
    search () {
      this.$emit('onSearchEmpleado', this.searchName)
    },
    resetSearch () {
      this.searchName = ''
    }
  }
}
</script>
