<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-2">
        <label class="block text-sm leading-5 text-gray-700">Fecha Consulta</label>
        <datepicker v-model="fechaTurno" :language="es" :format="format" @closed="consultaTurno"></datepicker>
      </div>
    </div>
    <div class="grid grid-cols-11 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Empleados en turno</h4>
      </div>
      <div class="col-span-2 ml-auto">
        <router-link to="/turno-ajustes" class="bg-gray-700 hover:bg-gray-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline">Correciones</router-link>
      </div>
    </div>
    <ListTurno :registroTurnoSucursal="registroTurnoSucursal" :fechaTurno="fechaTurno" class="mb-6"/>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/src/locale'
import authClient from '@/utils/auth'
import moment from 'moment-timezone'
import ListTurno from '@/components/ListTurno'
export default {
  name: 'Turno',
  components: {
    Datepicker,
    ListTurno
  },
  data () {
    return {
      es: es,
      format: 'd MMMM yyyy',
      fechaTurno: this.$store.getters.getFechaTurno,
      empresaSeleccionada: this.$store.getters.empresaSeleccionada,
      registroTurnoSucursal: []
    }
  },
  methods: {
    consultaTurno () {
      this.registroTurnoSucursal = []
      let fecha = moment().format('YYYY-MM-DD')

      if (moment(this.fechaTurno).isValid()) {
        this.$store.dispatch('FECHA_TURNO', this.fechaTurno)
        fecha = moment(this.fechaTurno).format('YYYY-MM-DD')
      }

      this.$http.post('/api/turno-laborado/empleado-turno-fecha', { empresa: this.empresaSeleccionada, fecha }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          this.registroTurnoSucursal = data
        })
    }
  },
  mounted () {
    this.consultaTurno()
  }
}
</script>
