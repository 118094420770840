<template>
  <tr>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.empleado.numero_empleado }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.empleado.primer_nombre }} {{ registro.empleado.segundo_nombre }} {{ registro.empleado.apellido_paterno }} {{ registro.empleado.apellido_materno }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.sueldo | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.septimo_dia | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.prima_dominical | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.gratificacion | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.retroactivo | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.vacaciones_tiempo | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.vacaciones_reportadas | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.prima_tiempo | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.prima_vacacional | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.aguinaldo | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.total_percepciones | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.seguro_vivienda_infonavit | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.subsidio_empleo_sp | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.subs_empleo_mes | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.isr | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.imss | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.prestamo_infonavit | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.prestamo_empresa | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.ajuste_subsidio_empleo | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.subs_entregado_no_correspondia | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.ajuste_neto | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.isr_ajuste_mensual | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.isr_ajustado_subsidio | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.ajuste_subsidio_causado | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.descuento_corte | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.descuento_multa | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.descuento_inventario | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.total_deducciones | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.neto | currency }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ItemNominaFiscal',
  props: {
    registro: Object
  }
}
</script>
