<template>
  <tr>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ semana.semana }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ semana.periodo }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ semana.fecha_inicial | moment("DD MMMM YYYY") }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ semana.fecha_final | moment("DD MMMM YYYY") }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium">
      <router-link :to="{ name: 'nomina-incidencias', params: { id: semana.id }}" class="text-red-600 hover:text-red-900">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
          <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z"/>
          <path d="M14 2v6h6M16 13H8M16 17H8M10 9H8"/>
        </svg>
      </router-link>
    </td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium">
      <button @click="downloadIncidencias(semana.id, semana.semana)" class="text-red-600 hover:text-red-900 focus:outline-none">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download">
          <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M7 10l5 5 5-5M12 15V3"/>
        </svg>
      </button>
    </td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium">
      <router-link :to="{ name: 'nomina-fiscal', params: { id: semana.id }}" class="text-red-600 hover:text-red-900">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload">
          <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M17 8l-5-5-5 5M12 3v12"/>
        </svg>
      </router-link>
    </td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium">
      <router-link :to="{ name: 'nomina-pension', params: { id: semana.id }}" class="text-red-600 hover:text-red-900">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-plus">
          <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z"/>
          <path d="M14 2v6h6M12 18v-6M9 15h6"/>
        </svg>
      </router-link>
    </td>
  </tr>
</template>

<script>
import authClient from '@/utils/auth'
export default {
  name: 'ItemSemanaNomina',
  props: {
    semana: Object
  },
  data () {
    return {
      nombreEmpresa: ''
    }
  },
  methods: {
    downloadIncidencias (semanaId, semana) {
      this.$http.post('/api/turno-nomina/incidencias-semana-xls', { semanaId, empresaId: this.$store.getters.empresaSeleccionada }, {
        responseType: 'blob',
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `Incidencias ${this.nombreEmpresa} semana ${semana}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }
  },
  mounted () {
    if (this.nombreEmpresa === '') {
      this.$http.get(`/api/empresa/${this.$store.getters.empresaSeleccionada}`)
        .then(({ data }) => (this.nombreEmpresa = data.nombre))
    }
  }
}
</script>
