<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Vacaciones</h4>
      </div>
      <div class="col-span-3 justify-self-end">
        <NavExpediente :registroEmpleado="this.$route.params.nombreempleado" :empleadoId="this.$route.params.id" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-8">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Dias de disponibles</label>
        <input v-model="vacacionesDisponibles" readonly class="input-readonly">
      </div>
      <div class="col-span-9 text-right">
        <router-link :to="{ name: 'vacaciones-nuevo', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="mt-6 py-1 px-4 border border-transparent text-xs leading-5 rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none">Registrar Vacaciones</router-link>
      </div>
    </div>
    <div class="grid grid-cols-9 mb-6">
      <div class="col-span-2">
        <button :class="{ 'border-blue-600': vacacionesTab }" @click="changeTab(0)" class="py-1 px-4 uppercase text-xs leading-5 text-gray-700 border-b-2 hover:border-blue-600 focus:outline-none">Vacaciones Solicitadas</button>
      </div>
      <div class="col-span-2">
        <button :class="{ 'border-blue-600': !vacacionesTab }" @click="changeTab(1)" class="py-1 px-4 uppercase text-xs leading-5 text-gray-700 border-b-2 hover:border-blue-600 focus:outline-none">Vacaciones</button>
      </div>
    </div>
    <div v-show="vacacionesTab" class="grid grid-cols-12 gap-6 mb-8">
      <div class="col-span-12 shadow overflow-hidden sm:rounded">
        <table class="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Fecha Solicitud</th>
              <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Fecha Inicial</th>
              <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Fecha Final</th>
              <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Dias Solicitados</th>
              <th class="px-6 py-3"></th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr v-for="(vacacionSolicitada, key) in vacacionesSolicitadas" :key="key">
              <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ vacacionSolicitada.fecha_solicitud | moment("DD MMMM YYYY") }}</td>
              <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ vacacionSolicitada.fecha_inicial | moment("DD MMMM YYYY") }}</td>
              <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ vacacionSolicitada.fecha_final | moment("DD MMMM YYYY") }}</td>
              <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ vacacionSolicitada.dias_solicitados }}</td>
              <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium">
                <button @click="downloadPdf(vacacionSolicitada.id)" class="text-red-600 hover:text-red-900 focus:outline-none">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
                    <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z"/>
                    <path d="M14 2v6h6M16 13H8M16 17H8M10 9H8"/>
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-show="!vacacionesTab" class="grid grid-cols-12 gap-6 mb-8">
      <div class="col-span-12 shadow overflow-hidden sm:rounded">
        <table class="min-w-full divide-y divide-gray-200">
          <thead>
            <tr>
              <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Fecha</th>
              <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Antiguedad (Años)</th>
              <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Dias Asignados</th>
            </tr>
          </thead>
          <tbody class="divide-y divide-gray-200">
            <tr v-for="(vacacionAsignada, key) in vacacionesAsignadas" :key="key">
              <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ vacacionAsignada.fecha | moment("DD MMMM YYYY") }}</td>
              <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ vacacionAsignada.antiguedad }}</td>
              <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ vacacionAsignada.dias_asignados }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import NavExpediente from '@/components/NavExpediente'
import authClient from '@/utils/auth'
export default {
  name: 'EmpleadoVacaciones',
  components: {
    NavExpediente
  },
  data () {
    return {
      vacacionesDisponibles: 0,
      vacacionesTab: true,
      vacacionesAsignadas: [],
      vacacionesSolicitadas: []
    }
  },
  methods: {
    obtenerVacacionesDisponibles () {
      this.$http.post('/api/empleado/vacaciones-disponibles', { empleado: this.$route.params.id }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.vacacionesDisponibles = data.vacaciones_disponibles))
    },
    obtenerVacacionesAsignadas () {
      this.$http.post('/api/exp-vacaciones/vacaciones-asignadas', { empleado: this.$route.params.id }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.vacacionesAsignadas = data))
    },
    obtenervacacionesSolicitadas () {
      this.$http.post('/api/exp-vacaciones-solicitadas/vacaciones-solicitadas', { empleado: this.$route.params.id }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.vacacionesSolicitadas = data))
    },
    changeTab (tab) {
      (tab === 0) ? this.vacacionesTab = true : this.vacacionesTab = false
    },
    downloadPdf (pdfId) {
      this.$http.post('/api/exp-vacaciones-solicitadas/formato-vacaciones-pdf', { id: pdfId }, {
        responseType: 'blob',
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `vacaciones_${this.$route.params.nombreempleado.replace(/ /g, '')}`
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }
  },
  mounted () {
    this.obtenerVacacionesDisponibles()
    this.obtenerVacacionesAsignadas()
    this.obtenervacacionesSolicitadas()
  }
}
</script>
