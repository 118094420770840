<template>
  <div class="flex flex-col" v-if="registroTurnoSucursal.length > 0">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden sm:rounded">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Sucursal</th>
                <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroTurnoSucursal[0].empleados)[0] | moment("ddd DD MMM") }}</th>
                <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroTurnoSucursal[0].empleados)[1] | moment("ddd DD MMM") }}</th>
                <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroTurnoSucursal[0].empleados)[2] | moment("ddd DD MMM") }}</th>
                <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroTurnoSucursal[0].empleados)[3] | moment("ddd DD MMM") }}</th>
                <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroTurnoSucursal[0].empleados)[4] | moment("ddd DD MMM") }}</th>
                <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroTurnoSucursal[0].empleados)[5] | moment("ddd DD MMM") }}</th>
                <th class="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroTurnoSucursal[0].empleados)[6] | moment("ddd DD MMM") }}</th>
                <th class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <ItemTurno v-for="(registro, key) in registroTurnoSucursal" :registro="registro" :key="key" class="bg-white odd:bg-gray-100" />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemTurno from './ItemTurno'
export default {
  name: 'ListTurno',
  props: {
    registroTurnoSucursal: Array
  },
  components: {
    ItemTurno
  }
}
</script>
