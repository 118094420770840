<template>
    <td>
      <select v-model="itemSelected" @change="updateHorarioEmpleadoSucursal" class="px-2 block appearance-none w-full whitespace-no-wrap bg-opacity-0 bg-gray-200 text-gray-700 text-xs rounded leading-tight focus:outline-none cursor-pointer">
        <option :value="1">Valle Poniente</option>
        <option :value="2">Valle Oriente</option>
        <option :value="3">Serrania</option>
        <option :value="4">San Jeronimo</option>
        <option :value="5">Contry</option>
        <option :value="7">Leones</option>
        <option :value="10">Brisas</option>
        <option :value="27">Gomez Morin</option>
      </select>
    </td>
</template>

<script>
export default {
  name: 'ItemHorarioEmpleadoSucursal',
  props: {
    item: Object
  },
  data () {
    return {
      itemSelected: this.item.sucursalId
    }
  },
  methods: {
    updateHorarioEmpleadoSucursal () {
      this.$emit('onUpdateHorarioEmpleadoSucursal', this.item.id, this.itemSelected)
    }
  }
}
</script>
