<template>
  <tr>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ empleado.numero_empleado }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">
      {{ empleado.primer_nombre }} {{ empleado.segundo_nombre }} {{ empleado.apellido_paterno }} {{ empleado.apellido_materno }}
    </td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ empleado.rfc }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ empleado.nss }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ empleado.sucursal.nombre }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ empleado.fecha_ingreso | moment("DD MMM YYYY") }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium">
      <router-link :to="{ name: 'empleado', params: { id: empleado.id } }" class="text-red-600 hover:text-red-900">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text">
          <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z"/>
          <path d="M14 2v6h6M16 13H8M16 17H8M10 9H8"/>
        </svg>
      </router-link>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ItemEmpleado',
  props: {
    empleado: Object
  }
}
</script>
