<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link :to="{ name: 'empleado-baja', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-6 mb-10">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createBono">
          <div class="shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-12">
                  <p class="uppercase text-base text-gray-700 font-medium tracking-wider">Procesar Baja</p>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Fecha Baja</label>
                  <datepicker v-model="fechaBaja" :language="es" :format="format"></datepicker>
                </div>
                <div class="col-span-4">
                  <label class="block text-sm leading-5 text-gray-700">Motivo</label>
                  <select v-model="motivo" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option>Mejorar económicamente</option>
                    <option>Distancia de su domicilio</option>
                    <option>Motivos de salud</option>
                    <option>Continuar con sus estudios</option>
                    <option>Buscar otro tipo de empleo</option>
                    <option>Por el ambiente de trabajo</option>
                    <option>Por alguien en particular</option>
                    <option>Por el horario</option>
                    <option>Por el gerente</option>
                    <option>Otro</option>
                  </select>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Dias pendientes por pagar</label>
                  <input v-model="diasPagar" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none" placeholder="0">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Bonos pendientes por pagar</label>
                  <input v-model="bonosPagar" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none" placeholder="0.00">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Cuentas por cobrar</label>
                  <input v-model="cuentasCobrar" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none" placeholder="0.00">
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Renuncia firmada</label>
                  <select v-model="renuncia" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Uniformes entregados</label>
                  <select v-model="uniformes" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Contratable</label>
                  <select v-model="contratable" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option value="true">Si</option>
                    <option value="false">No</option>
                  </select>
                </div>
              </div>
              <button class="py-1 px-4 border border-transparent text-sm leading-5 rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none">
                Registrar Baja
              </button>
              <span v-show="errorCaptura" class="text-red-600 text-sm ml-6">Error al registrar baja, verificar datos.</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/src/locale'
import moment from 'moment-timezone'
export default {
  name: 'NuevoBaja',
  components: {
    Datepicker
  },
  data () {
    return {
      es: es,
      format: 'd MMMM yyyy',
      fechaBaja: '',
      motivo: 'Mejorar económicamente',
      diasPagar: '',
      bonosPagar: '',
      cuentasCobrar: '',
      renuncia: true,
      uniformes: true,
      contratable: true,
      errorCaptura: false
    }
  },
  methods: {
    createBono () {
      let fecha = moment().format('YYYY-MM-DD')

      if (moment(this.fechaBaja).isValid()) {
        fecha = moment(this.fechaBaja).format('YYYY-MM-DD')
      }

      // Valida fecha posterior
      const fechaValid = moment().isAfter(fecha)

      if (!fechaValid) {
        this.errorCaptura = true
        setTimeout(() => (this.errorCaptura = false), 5000)
        return
      }

      const bajaObject = {
        fecha_baja: fecha,
        motivo: this.motivo,
        dias_pagar: parseInt(this.diasPagar),
        bonos_pagar: parseFloat(this.bonosPagar),
        cuentas_cobrar: parseFloat(this.cuentasCobrar),
        renuncia: this.renuncia,
        uniformes: this.uniformes,
        contratable: this.contratable,
        empleadoId: this.$route.params.id
      }

      this.$http.post('/api/exp-baja/baja', bajaObject, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => this.$router.push({ name: 'empleado-baja', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }))
        .catch(() => {
          this.errorCaptura = true
          setTimeout(() => (this.errorCaptura = false), 5000)
        })
    }
  }
}
</script>
