<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Baja</h4>
      </div>
      <div class="col-span-3 justify-self-end">
        <NavExpediente :registroEmpleado="this.$route.params.nombreempleado" :empleadoId="this.$route.params.id" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-8">
      <div class="col-span-3">
        <button :disabled="estatusDescargaRenunciaFlag" class="bg-gray-700 hover:bg-gray-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-auto" @click.prevent="downloadRenuncia">{{ estatusDescargaRenuncia }}</button>
      </div>
      <div class="col-span-3" v-show="!baja">
        <router-link :to="{ name: 'baja-nuevo', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="bg-blue-500 hover:bg-blue-600 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-auto">Procesar Baja</router-link>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Fecha Baja</label>
        <input v-model="baja.fecha_baja" readonly class="input-readonly">
      </div>
      <div class="col-span-5">
        <label class="block text-sm leading-5 text-gray-700">Motivo</label>
        <input v-model="baja.motivo" readonly class="input-readonly">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Contratable</label>
        <input v-model="contratable" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Renuncia firmada</label>
        <input v-model="renuncia" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Uniformes entregados</label>
        <input v-model="uniforme" readonly class="input-readonly">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Dias por pagar</label>
        <input v-model="baja.dias_pagar" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Bonos por pagar</label>
        <input v-model="baja.bonos_pagar" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Cuentas por cobrar</label>
        <input v-model="baja.cuentas_cobrar" readonly class="input-readonly">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Vacaciones Gozadas</label>
        <input v-model="baja.vacaciones_gozadas" readonly class="input-readonly">
      </div>
    </div>
  </div>
</template>

<script>
import NavExpediente from '@/components/NavExpediente'
import authClient from '@/utils/auth'
export default {
  name: 'EmpleadoBaja',
  components: {
    NavExpediente
  },
  data () {
    return {
      estatusDescargaRenuncia: 'Formato Renuncia',
      estatusDescargaRenunciaFlag: false,
      baja: {
        fecha_baja: '',
        motivo: '',
        contratable: true,
        dias_pagar: 0,
        bonos_pagar: 0,
        cuentas_cobrar: 0,
        renuncia: true,
        uniforme: true,
        vacaciones_gozadas: 0
      }
    }
  },
  methods: {
    obtenerBaja () {
      this.$http.get(`/api/exp-baja/${this.$route.params.id}/empleado`, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      }).then(({ data }) => (this.baja = data))
    },
    downloadRenuncia () {
      this.estatusDescargaRenuncia = 'Descargando...'
      this.estatusDescargaRenunciaFlag = true
      this.$http.get(`/api/exp-baja/${this.$route.params.id}/formato-renuncia-pdf`, {
        responseType: 'blob',
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `formatorenuncia_${this.$route.params.nombreempleado.replace(/ /g, '')}`
          link.click()
          URL.revokeObjectURL(link.href)
          this.estatusDescargaRenuncia = 'Formato Renuncia'
          this.estatusDescargaRenunciaFlag = false
        })
    }
  },
  computed: {
    contratable: {
      get () {
        return (this.baja.contratable) ? 'Si' : 'No'
      }
    },
    renuncia: {
      get () {
        return (this.baja.renuncia) ? 'Si' : 'No'
      }
    },
    uniforme: {
      get () {
        return (this.baja.uniforme) ? 'Si' : 'No'
      }
    }
  },
  mounted () {
    this.obtenerBaja()
  }
}
</script>
