import Vue from 'vue'
import VueRouter from 'vue-router'
import authClient from '@/utils/auth'
import Login from '@/views/Login'
import Home from '@/views/Home'
import Bajas from '@/views/Bajas'
import Turno from '@/views/Turno'
import TurnoDetalle from '@/views/TurnoDetalle'
import TurnoAjustes from '@/views/TurnoAjustes'
import Empleado from '@/views/Empleado'
import NuevoEmpleado from '@/views/NuevoEmpleado'
import EmpleadoContrato from '@/views/EmpleadoContrato'
import EmpleadoTarjeta from '@/views/EmpleadoTarjeta'
import EmpleadoDescuentos from '@/views/EmpleadoDescuentos'
import EmpleadoBaja from '@/views/EmpleadoBaja'
import EmpleadoBonos from '@/views/EmpleadoBonos'
import EmpleadoFiniquito from '@/views/EmpleadoFiniquito'
import EmpleadoPrestamos from '@/views/EmpleadoPrestamos'
import EmpleadoPrimaVacacional from '@/views/EmpleadoPrimaVacacional'
import EmpleadoPuestos from '@/views/EmpleadoPuestos'
import EmpleadoSueldos from '@/views/EmpleadoSueldos'
import EmpleadoVacaciones from '@/views/EmpleadoVacaciones'
import EmpleadoSucursales from '@/views/EmpleadoSucursales'
import EmpleadoNomina from '@/views/EmpleadoNomina'
import Nomina from '@/views/Nomina'
import NominaIncidencias from '@/views/NominaIncidencias'
import NuevoDescuento from '@/views/NuevoDescuento'
import NuevoVacaciones from '@/views/NuevoVacaciones'
import NuevoBono from '@/views/NuevoBono'
import NuevoPuesto from '@/views/NuevoPuesto'
import NuevoSueldo from '@/views/NuevoSueldo'
import NuevoSucursal from '@/views/NuevoSucursal'
import NuevoBaja from '@/views/NuevoBaja'
import NuevoNomina from '@/views/NuevoNomina'
import NominaFiscal from '@/views/NominaFiscal'
import NominaPension from '@/views/NominaPension'
import Horario from '@/views/Horario'
import HorarioDetalleEmpleado from '@/views/HorarioDetalleEmpleado'
import Externos from '@/views/Externos'
import NuevoEmpleadoExterno from '@/views/NuevoEmpleadoExterno'
import EmpleadoExterno from '@/views/EmpleadoExterno'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/bajas',
    name: 'bajas',
    component: Bajas,
    meta: { requiresAuth: true }
  },
  {
    path: '/turno',
    name: 'turno',
    component: Turno,
    meta: { requiresAuth: true }
  },
  {
    path: '/turno-detalle',
    name: 'turno-detalle',
    component: TurnoDetalle,
    meta: { requiresAuth: true }
  },
  {
    path: '/turno-ajustes',
    name: 'turno-ajustes',
    component: TurnoAjustes,
    meta: { requiresAuth: true }
  },
  {
    path: '/horario',
    name: 'horario',
    component: Horario,
    meta: { requiresAuth: true }
  },
  {
    path: '/horario-detalle-empleado',
    name: 'horario-detalle-empleado',
    component: HorarioDetalleEmpleado,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id',
    name: 'empleado',
    component: Empleado,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/contrato',
    name: 'empleado-contrato',
    component: EmpleadoContrato,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/tarjeta',
    name: 'empleado-tarjeta',
    component: EmpleadoTarjeta,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/descuentos',
    name: 'empleado-descuentos',
    component: EmpleadoDescuentos,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/baja',
    name: 'empleado-baja',
    component: EmpleadoBaja,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/bonos',
    name: 'empleado-bonos',
    component: EmpleadoBonos,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/finiquito',
    name: 'empleado-finiquito',
    component: EmpleadoFiniquito,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/prestamos',
    name: 'empleado-prestamos',
    component: EmpleadoPrestamos,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/primavacacional',
    name: 'empleado-primavacacional',
    component: EmpleadoPrimaVacacional,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/puestos',
    name: 'empleado-puestos',
    component: EmpleadoPuestos,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/sueldos',
    name: 'empleado-sueldos',
    component: EmpleadoSueldos,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/vacaciones',
    name: 'empleado-vacaciones',
    component: EmpleadoVacaciones,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/sucursales',
    name: 'empleado-sucursales',
    component: EmpleadoSucursales,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado/:id/nomina',
    name: 'empleado-nomina',
    component: EmpleadoNomina,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado-nuevo',
    name: 'empleado-nuevo',
    component: NuevoEmpleado,
    meta: { requiresAuth: true }
  },
  {
    path: '/descuento-nuevo/:id',
    name: 'descuento-nuevo',
    component: NuevoDescuento,
    meta: { requiresAuth: true }
  },
  {
    path: '/vacaciones-nuevo/:id',
    name: 'vacaciones-nuevo',
    component: NuevoVacaciones,
    meta: { requiresAuth: true }
  },
  {
    path: '/bono-nuevo/:id',
    name: 'bono-nuevo',
    component: NuevoBono,
    meta: { requiresAuth: true }
  },
  {
    path: '/puesto-nuevo/:id',
    name: 'puesto-nuevo',
    component: NuevoPuesto,
    meta: { requiresAuth: true }
  },
  {
    path: '/sueldo-nuevo/:id',
    name: 'sueldo-nuevo',
    component: NuevoSueldo,
    meta: { requiresAuth: true }
  },
  {
    path: '/sucursal-nuevo/:id',
    name: 'sucursal-nuevo',
    component: NuevoSucursal,
    meta: { requiresAuth: true }
  },
  {
    path: '/baja-nuevo/:id',
    name: 'baja-nuevo',
    component: NuevoBaja,
    meta: { requiresAuth: true }
  },
  {
    path: '/nomina-nuevo/:id',
    name: 'nomina-nuevo',
    component: NuevoNomina,
    meta: { requiresAuth: true }
  },
  {
    path: '/nomina',
    name: 'nomina',
    component: Nomina,
    meta: { requiresAuth: true }
  },
  {
    path: '/nomina-incidencias',
    name: 'nomina-incidencias',
    component: NominaIncidencias,
    meta: { requiresAuth: true }
  },
  {
    path: '/nomina-fiscal',
    name: 'nomina-fiscal',
    component: NominaFiscal,
    meta: { requiresAuth: true }
  },
  {
    path: '/nomina-pension',
    name: 'nomina-pension',
    component: NominaPension,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/externos',
    name: 'externos',
    component: Externos,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado-externo-nuevo',
    name: 'empleado-externo-nuevo',
    component: NuevoEmpleadoExterno,
    meta: { requiresAuth: true }
  },
  {
    path: '/empleado-externo/:id',
    name: 'empleado-externo',
    component: EmpleadoExterno,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, _, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!authClient.isAuthenticated()) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
