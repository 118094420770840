<template>
  <tr>
    <td class="px-6 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ ajuste.semana.semana }} - {{ ajuste.semana.periodo }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ ajuste.dias_laborados }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ ajuste.dias_puntualidad }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ ajuste.dias_descanso }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ (ajuste.prima_dominical) ? 'Si' : 'No' }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ (ajuste.bono_asistencia) ? 'Si' : 'No' }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ ajuste.motivo.substring(0, 40) }}{{ (ajuste.motivo.substring(0, 40).length === 40) ? '...' : '' }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ItemAjustesNomina',
  props: {
    ajuste: Object
  }
}
</script>
