<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Finiquito</h4>
      </div>
      <div class="col-span-3 justify-self-end">
        <NavExpediente :registroEmpleado="this.$route.params.nombreempleado" :empleadoId="this.$route.params.id" />
      </div>
    </div>
    <form @submit.prevent="registraFiniquito">
      <div class="grid grid-cols-12 gap-6 mb-6">
        <div class="col-span-3">
          <label class="block text-sm leading-5 text-gray-700">Fecha Calculo</label>
          <datepicker :disabled="estatusFiniquitoEntregado" v-model="fechaCalculo" :language="es" :format="format"></datepicker>
        </div>
        <div class="col-span-2">
          <label class="block text-sm leading-5 text-gray-700">Monto</label>
          <input :disabled="estatusFiniquitoEntregado" v-model="monto" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none" placeholder="0.00">
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6 mb-6">
        <div class="col-span-2">
          <label class="block text-sm leading-5 text-gray-700">Entregado</label>
          <select :disabled="estatusFiniquitoEntregado" v-model="entregado" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
            <option value="true">Si</option>
            <option value="false">No</option>
          </select>
        </div>
        <div class="col-span-3">
          <label class="block text-sm leading-5 text-gray-700">Fecha Entregado</label>
          <datepicker :disabled="estatusFiniquitoEntregado" v-model="fechaEntregado" :language="es" :format="format"></datepicker>
        </div>
      </div>
      <div class="grid grid-cols-12 gap-6 mb-6">
        <div class="col-span-4">
          <div class="py-6 bg-gray-50">
            <button :disabled="estatusFiniquitoEntregado" class="py-1 px-4 border border-transparent text-xs leading-5 rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none">
              {{ estatusFiniquito ? 'Actualizar Finiquito': 'Registrar Finiquito' }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/src/locale'
import moment from 'moment'
import NavExpediente from '@/components/NavExpediente'
export default {
  name: 'EmpleadoFiniquito',
  components: {
    NavExpediente,
    Datepicker
  },
  data () {
    return {
      es: es,
      format: 'd MMMM yyyy',
      fechaCalculo: '',
      fechaEntregado: '',
      entregado: false,
      monto: '',
      estatusFiniquito: false,
      estatusFiniquitoEntregado: false
    }
  },
  methods: {
    obtenerFiniquito () {
      this.$http.post('/api/exp-finiquito/empleado-finiquito', { empleadoId: this.$route.params.id }, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          if (data) {
            this.estatusFiniquito = true
            if (data.fecha_calculo) {
              this.fechaCalculo = moment(data.fecha_calculo).format()
            }
            if (data.fecha_entregado) {
              this.fechaEntregado = moment(data.fecha_entregado).format()
            }
            this.entregado = data.entregado
            this.monto = data.monto
            this.estatusFiniquitoEntregado = data.entregado
          }
        })
    },
    registraFiniquito () {
      const objectFiniquito = {}
      if (!moment(this.fechaCalculo).isValid()) return
      objectFiniquito.fecha_calculo = moment(this.fechaCalculo).format('YYYY-MM-DD')

      if (moment(this.fechaEntregado).isValid()) {
        objectFiniquito.fecha_entregado = moment(this.fechaEntregado).format('YYYY-MM-DD')
      }

      if (this.entregado && moment(this.fechaEntregado).isValid()) {
        objectFiniquito.entregado = this.entregado
      }

      objectFiniquito.monto = this.monto
      objectFiniquito.empleadoId = this.$route.params.id

      this.$http.post('/api/exp-finiquito/registro-finiquito', objectFiniquito, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(() => this.obtenerFiniquito())
    }
  },
  mounted () {
    this.obtenerFiniquito()
  }
}
</script>
