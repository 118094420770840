<template>
  <tr>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900 text-center">{{ registro.empleado.numero_empleado }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.empleado.primer_nombre }} {{ registro.empleado.segundo_nombre }} {{ registro.empleado.apellido_paterno }} {{ registro.empleado.apellido_materno }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.empleado.sucursal.clave }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.empleado.sueldo_diario | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900 text-center">{{ registro.faltas }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900 text-center">{{ registro.vacaciones }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ (registro.prima_dominical) ? 'Si' : 'No' }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ (registro.prima_vacacional) ? 'Si' : 'No' }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.descuento_multa | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.descuento_corte | currency }}</td>
    <td class="px-2 py-2 whitespace-no-wrap text-xs leading-4 font-medium text-gray-900">{{ registro.descuento_inventario | currency }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ItemIncidencias',
  props: {
    registro: Object
  }
}
</script>
