<template>
  <tr>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.empleado.numero_empleado }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.empleado.primer_nombre }} {{ registro.empleado.segundo_nombre }} {{ registro.empleado.apellido_paterno }} {{ registro.empleado.apellido_materno }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.nomina_fiscal | currency }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.deducciones | currency }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.sueldo_real | currency }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.bono_puntualidad | currency }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.bono_asistencia | currency }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.bono_antiguedad | currency }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.bono_entrenador | currency }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.bono_otros | currency }}</td>
    <td class="px-1 py-2 whitespace-no-wrap text-xs leading-4 text-gray-900">{{ registro.deposito | currency }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ItemNominaPension',
  props: {
    registro: Object
  }
}
</script>
