<template>
  <div class="flex flex-col" v-if="registroHorarioSucursal.length > 0">
    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden sm:rounded">
          <table class="min-w-full divide-y divide-gray-200">
            <thead>
              <tr>
                <th class="px-4 py-3 text-left text-xs text-center leading-4 font-medium text-gray-500 uppercase tracking-wider">#</th>
                <th class="px-2 py-3 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                <th class="px-2 py-3 text-left text-xs text-center leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroHorarioSucursal[0].semana)[0] | moment("ddd DD MMM") }}</th>
                <th class="px-2 py-3 text-left text-xs text-center leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroHorarioSucursal[0].semana)[1] | moment("ddd DD MMM") }}</th>
                <th class="px-2 py-3 text-left text-xs text-center leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroHorarioSucursal[0].semana)[2] | moment("ddd DD MMM") }}</th>
                <th class="px-2 py-3 text-left text-xs text-center leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroHorarioSucursal[0].semana)[3] | moment("ddd DD MMM") }}</th>
                <th class="px-2 py-3 text-left text-xs text-center leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroHorarioSucursal[0].semana)[4] | moment("ddd DD MMM") }}</th>
                <th class="px-2 py-3 text-left text-xs text-center leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroHorarioSucursal[0].semana)[5] | moment("ddd DD MMM") }}</th>
                <th class="px-2 py-3 text-left text-xs text-center leading-4 font-medium text-gray-500 uppercase tracking-wider">{{ Object.keys(registroHorarioSucursal[0].semana)[6] | moment("ddd DD MMM") }}</th>
                <th class="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <ItemHorario v-for="(registro, key) in registroHorarioSucursal" :registro="registro" :key="key" @onUpdateHorario="updateHorario" class="bg-white odd:bg-gray-100" />
            </tbody>
            <tfoot class="divide-y divide-gray-200" v-if="registroHorarioTotal.length > 0">
              <tr>
                <td class="px-4 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900"></td>
                <td class="px-2 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900">Total</td>
                <td class="px-2 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900">{{ registroHorarioTotal[0].semana[[Object.keys(registroHorarioTotal[0].semana)[0]]] }}</td>
                <td class="px-2 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900">{{ registroHorarioTotal[0].semana[[Object.keys(registroHorarioTotal[0].semana)[1]]] }}</td>
                <td class="px-2 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900">{{ registroHorarioTotal[0].semana[[Object.keys(registroHorarioTotal[0].semana)[2]]] }}</td>
                <td class="px-2 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900">{{ registroHorarioTotal[0].semana[[Object.keys(registroHorarioTotal[0].semana)[3]]] }}</td>
                <td class="px-2 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900">{{ registroHorarioTotal[0].semana[[Object.keys(registroHorarioTotal[0].semana)[4]]] }}</td>
                <td class="px-2 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900">{{ registroHorarioTotal[0].semana[[Object.keys(registroHorarioTotal[0].semana)[5]]] }}</td>
                <td class="px-2 py-3 whitespace-no-wrap text-xs text-center leading-4 font-medium text-gray-900">{{ registroHorarioTotal[0].semana[[Object.keys(registroHorarioTotal[0].semana)[6]]] }}</td>
                <th class="px-6 py-3"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemHorario from './ItemHorario'
export default {
  name: 'ListHorario',
  props: {
    registroHorarioSucursal: Array,
    registroHorarioTotal: Array
  },
  components: {
    ItemHorario
  },
  methods: {
    updateHorario (empleado, fecha, itemSelected) {
      this.$emit('onUpdateHorario', empleado, fecha, itemSelected)
    }
  }
}
</script>
