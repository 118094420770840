<template>
    <td><select v-model="itemSelected" @change="updateHorarioEmpleadoConcepto"
    class="px-2 block appearance-none w-full whitespace-no-wrap bg-opacity-0 bg-gray-200 text-gray-700 text-xs rounded leading-tight focus:outline-none cursor-pointer" :class="[
      (item.concepto === 4)?'text-red-500': 'text-gray-900',
      (item.concepto === 2)?'text-green-500': 'text-gray-900',
      (item.concepto === 3)?'text-orange-500': 'text-gray-900',
      (item.concepto === 5)?'text-purple-500': 'text-gray-900',
      (item.concepto === 6)?'text-blue-500': 'text-gray-900',
      (item.concepto === 7)?'text-red-500': 'text-gray-900',
      (item.concepto === 8)?'text-indigo-500': 'text-gray-900',
    ]">
        <option :value="1">Laborado</option>
        <option :value="2">Descanso</option>
        <option :value="3">Desc. Laborado</option>
        <option :value="4">Baja</option>
        <option :value="5">Incapacidad</option>
        <option :value="6">Vacaciones</option>
        <option :value="7">Falta</option>
        <option :value="8">Permiso</option>
      </select>
    </td>
</template>

<script>
export default {
  name: 'ItemHorarioEmpleadoConcepto',
  props: {
    item: Object
  },
  data () {
    return {
      itemSelected: this.item.concepto
    }
  },
  methods: {
    updateHorarioEmpleadoConcepto () {
      this.$emit('onUpdateHorarioEmpleadoConcepto', this.item.id, this.itemSelected)
    }
  }
}
</script>
