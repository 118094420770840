<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <router-link to="/externos" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de externos</router-link>
    </div>
    <h4 class="font-semibold uppercase mb-6">Externo Nuevo</h4>
    <div class="grid grid-cols-1 gap-6 mb-10">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createEmpleado">
          <div class="shadow overflow-hidden sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-4">
                  <label class="block text-sm leading-5 text-gray-700">Número de Externo</label>
                  <input v-model="numeroEmpleado" readonly class="bg-gray-300 mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
              </div>
              <div class="grid grid-cols-10 gap-6 mb-6">
                <div class="col-span-4">
                  <label class="block text-sm leading-5 text-gray-700">Primer Nombre</label>
                  <input v-model="empleadoForm.primer_nombre" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-4">
                  <label class="block text-sm leading-5 text-gray-700">Segundo Nombre</label>
                  <input v-model="empleadoForm.segundo_nombre" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-4">
                  <label class="block text-sm leading-5 text-gray-700">Apellido Paterno</label>
                  <input v-model="empleadoForm.apellido_paterno" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-4">
                  <label class="block text-sm leading-5 text-gray-700">Apellido Materno</label>
                  <input v-model="empleadoForm.apellido_materno" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">CURP</label>
                  <input v-model="empleadoForm.curp" size="18" maxlength="18" minlength="18" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">INE</label>
                  <input v-model="empleadoForm.ine" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Fecha Nacimiento</label>
                  <datepicker v-model="fechaNacimiento" :language="es" :format="format" required></datepicker>
                </div>
                <div class="col-span-5">
                  <label class="block text-sm leading-5 text-gray-700">Lugar Nacimiento</label>
                  <input v-model="empleadoForm.lugar_nacimiento" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Sexo</label>
                  <select v-model="empleadoForm.sexo" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option>Masculino</option>
                    <option>Femenino</option>
                  </select>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Nacionalidad</label>
                  <select v-model="empleadoForm.nacionalidad" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option>Mexicana</option>
                  </select>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Estado Civil</label>
                  <select v-model="empleadoForm.estado_civil" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option>Soltero</option>
                    <option>Casado</option>
                    <option>Divorciado</option>
                    <option>Viudo</option>
                    <option>Concubinato</option>
                  </select>
                </div>
                <hr class="col-span-12"/>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Fecha Ingreso</label>
                  <datepicker v-model="fechaIngreso" :language="es" :format="format" required></datepicker>
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Sucursal</label>
                  <select v-model="empleadoForm.sucursalId" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option v-for="(item, key) in sucursales" :value="item.id" :key="key">{{item.nombre}}</option>
                  </select>
                </div>
                <hr class="col-span-12"/>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-4">
                  <label class="block text-sm leading-5 text-gray-700">Correo Electrónico</label>
                  <input type="email" v-model="empleadoForm.correo_electronico" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Teléfono Celular</label>
                  <input type="tel" size="10" maxlength="10" minlength="10" v-model="empleadoForm.telefono_celular" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Teléfono Casa</label>
                  <input type="tel" size="10" maxlength="10" minlength="10" v-model="empleadoForm.telefono_casa" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <hr class="col-span-12"/>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-6">
                  <label class="block text-sm leading-5 text-gray-700">Domicilio Calle</label>
                  <input v-model="empleadoForm.domicilio_calle" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-2">
                  <label class="block text-sm leading-5 text-gray-700">No Exterior</label>
                  <input v-model="empleadoForm.domicilio_noexterior" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-2">
                  <label class="block text-sm leading-5 text-gray-700">No Interior</label>
                  <input v-model="empleadoForm.domicilio_nointerior" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-4">
                  <label class="block text-sm leading-5 text-gray-700">Colonia</label>
                  <input v-model="empleadoForm.domicilio_colonia" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Municipio</label>
                  <input v-model="empleadoForm.domicilio_municipio" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Entidad Federativa</label>
                  <select v-model="empleadoForm.domicilio_entidad_federativa" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
                    <option>Nuevo León</option>
                  </select>
                </div>
                <div class="col-span-2">
                  <label class="block text-sm leading-5 text-gray-700">Codigo Postal</label>
                  <input size="5" maxlength="5" minlength="5" v-model="empleadoForm.domicilio_codigo_postal" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <hr class="col-span-12"/>
              </div>
              <div class="grid grid-cols-12 gap-6">
                <div class="col-span-6">
                  <label class="block text-sm leading-5 text-gray-700">Referencia 1 Nombre</label>
                  <input v-model="empleadoForm.referencia_personal_uno_nombre" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Referencia 1 Teléfono</label>
                  <input type="tel" size="10" maxlength="10" minlength="10" v-model="empleadoForm.referencia_personal_uno_telefono" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-6">
                  <label class="block text-sm leading-5 text-gray-700">Referencia 2 Nombre</label>
                  <input v-model="empleadoForm.referencia_personal_dos_nombre" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Referencia 2 Teléfono</label>
                  <input type="tel" size="10" maxlength="10" minlength="10" v-model="empleadoForm.referencia_personal_dos_telefono" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
              </div>
            </div>
            <div class="py-4 bg-gray-50 px-6">
              <button class="py-1 px-4 border border-transparent text-sm leading-5 rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none">
                Registrar Externo
              </button>
              <span v-show="errorCaptura" class="text-red-600 text-sm ml-6">Error al registrar externo, verificar datos.</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/src/locale'
import authClient from '@/utils/auth'
import moment from 'moment-timezone'
export default {
  name: 'NuevoEmpleadoExterno',
  components: {
    Datepicker
  },
  data () {
    return {
      es: es,
      format: 'd MMMM yyyy',
      numeroEmpleado: 0,
      sucursales: [],
      empresaSeleccionada: this.$store.getters.empresaSeleccionada,
      errorCaptura: false,
      fechaNacimiento: '',
      fechaIngreso: '',
      empleadoForm: {
        numero_empleado: 0,
        primer_nombre: '',
        segundo_nombre: '',
        apellido_paterno: '',
        apellido_materno: '',
        curp: '',
        ine: '',
        fecha_nacimiento: '',
        lugar_nacimiento: '',
        nacionalidad: 'Mexicana',
        estado_civil: 'Soltero',
        sexo: 'Masculino',
        fecha_ingreso: '',
        correo_electronico: '',
        telefono_celular: '',
        telefono_casa: '',
        domicilio_calle: '',
        domicilio_noexterior: '',
        domicilio_nointerior: '',
        domicilio_colonia: '',
        domicilio_municipio: '',
        domicilio_entidad_federativa: 'Nuevo León',
        domicilio_codigo_postal: '',
        referencia_personal_uno_nombre: '',
        referencia_personal_uno_telefono: '',
        referencia_personal_dos_nombre: '',
        referencia_personal_dos_telefono: '',
        empresaId: this.$store.getters.empresaSeleccionada,
        sucursalId: 0
      }
    }
  },
  methods: {
    obtenerEmpleado () {
      this.$http.post('/api/empleado-externo/ultimo-empleado-empresa', { empresa: this.empresaSeleccionada }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          this.numeroEmpleado = parseInt(data) + 1
          this.empleadoForm.numero_empleado = parseInt(data) + 1
        })
    },
    obtenerSucursales () {
      this.$http.post('/api/sucursal/sucursales-empresa', { empresa: this.empresaSeleccionada }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          this.sucursales = data
          if (data.length > 0) {
            this.empleadoForm.sucursalId = data[0].id
          }
        })
    },
    createEmpleado () {
      this.$http.post('/api/empleado-externo/alta', this.empleadoForm, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => this.$router.push('/externos'))
        .catch(() => {
          this.errorCaptura = true
          setTimeout(() => (this.errorCaptura = false), 5000)
        })
    }
  },
  mounted () {
    this.obtenerEmpleado()
    this.obtenerSucursales()
  },
  watch: {
    fechaNacimiento (newVal, oldVal) {
      this.empleadoForm.fecha_nacimiento = moment(newVal).format('YYYY-MM-DD')
    },
    fechaIngreso (newVal, oldVal) {
      this.empleadoForm.fecha_ingreso = moment(newVal).format('YYYY-MM-DD')
    }
  }
}
</script>
