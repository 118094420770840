<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Cuenta Bancaria</h4>
      </div>
      <div class="col-span-3 justify-self-end">
        <NavExpediente :registroEmpleado="this.$route.params.nombreempleado" :empleadoId="this.$route.params.id" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-8">
      <div class="col-span-3">
        <button :disabled="estatusDescargaAnexoTarjetaFlag" class="bg-gray-700 hover:bg-gray-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-auto" @click.prevent="downloadAnexoTarjeta">{{ estatusDescargaAnexoTarjeta }}</button>
      </div>
    </div>
    <form class="grid grid-cols-12 gap-6 mb-6" @submit.prevent="registraCuenta">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Tarjeta Bancaria</label>
        <input v-model="tarjeta" size="16" maxlength="16" minlength="16" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Numero de Cuenta</label>
        <input v-model="cuenta" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">CLABE</label>
        <input v-model="clabe" size="18" maxlength="18" minlength="18" class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Banco</label>
        <select v-model="banco" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
          <option>Banorte</option>
          <option>Banamex</option>
          <option>Santander</option>
          <option>Bancomer</option>
          <option>Banregio</option>
          <option>HSBC</option>
          <option>Scotiabank</option>
        </select>
      </div>
      <div class="col-span-4">
        <div class="py-6 bg-gray-50">
          <button class="py-1 px-4 border border-transparent text-xs leading-5 rounded text-white bg-blue-500 hover:bg-blue-600 focus:outline-none">
            {{ cuentaEstatus ? 'Registrar Cuenta': 'Actualizar Cuenta' }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import NavExpediente from '@/components/NavExpediente'
import authClient from '@/utils/auth'
export default {
  name: 'EmpleadoTarjeta',
  components: {
    NavExpediente
  },
  data () {
    return {
      estatusDescargaAnexoTarjeta: 'Generar Anexo Tarjeta',
      estatusDescargaAnexoTarjetaFlag: false,
      tarjeta: '',
      cuenta: '',
      clabe: '',
      banco: 'Banorte',
      cuentaEstatus: true
    }
  },
  methods: {
    obtenerEmpleado () {
      this.$http.get(`/api/empleado/${this.$route.params.id}`, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          this.tarjeta = data.numero_tarjeta_bancaria
          this.cuenta = data.numero_cuenta_bancaria
          this.clabe = data.clabe_interbancaria
          this.banco = data.banco;
          (this.tarjeta === '' && this.cuenta === '' && this.clabe === '') ? this.cuentaEstatus = true : this.cuentaEstatus = false
        })
    },
    downloadAnexoTarjeta () {
      this.estatusDescargaAnexoTarjeta = 'Descargando...'
      this.estatusDescargaAnexoTarjetaFlag = true
      this.$http.get(`/api/empleado/${this.$route.params.id}/tarjeta-nomina-pdf`, {
        responseType: 'blob',
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `anexotarjeta_${this.$route.params.nombreempleado.replace(/ /g, '')}`
          link.click()
          URL.revokeObjectURL(link.href)
          this.estatusDescargaAnexoTarjeta = 'Generar Anexo Tarjeta'
          this.estatusDescargaAnexoTarjetaFlag = false
        })
    },
    registraCuenta () {
      this.$http.put(`/api/empleado/${this.$route.params.id}/tarjeta-nomina`, {
        tarjeta: this.tarjeta, cuenta: this.cuenta, clabe: this.clabe, banco: this.banco
      }, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          this.$router.push(`/empleado/${this.$route.params.id}`)
        })
    }
  },
  mounted () {
    this.obtenerEmpleado()
  }
}
</script>
