<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Contrato Laboral</h4>
      </div>
      <div class="col-span-3 justify-self-end">
        <NavExpediente :registroEmpleado="this.$route.params.nombreempleado" :empleadoId="this.$route.params.id" />
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-8">
      <div class="col-span-3">
        <button :disabled="estatusDescargaContratoFlag" class="bg-gray-700 hover:bg-gray-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-auto" @click.prevent="downloadContrato">{{ estatusDescargaContrato }}</button>
      </div>
      <div class="col-span-3">
        <button :disabled="estatusDescargaPensionFlag" class="bg-gray-700 hover:bg-gray-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-auto" @click.prevent="downloadPension">{{ estatusDescargaPension }}</button>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Firma Contrato Determinado A</label>
        <input v-model="fechaContratoA" readonly class="bg-gray-300 mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Firma Contrato Determinado B</label>
        <input v-model="fechaContratoB" readonly class="bg-gray-300 mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Firma Contrato Determinado C</label>
        <input v-model="fechaContratoC" readonly class="bg-gray-300 mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Firma Contrato Indeterminado</label>
        <input v-model="fechaContratoIndeterminado" readonly class="bg-gray-300 mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import NavExpediente from '@/components/NavExpediente'
import authClient from '@/utils/auth'
export default {
  name: 'EmpleadoContrato',
  components: {
    NavExpediente
  },
  data () {
    return {
      estatusDescargaContrato: 'Generar Contrato Laboral',
      estatusDescargaContratoFlag: false,
      estatusDescargaPension: 'Generar Contrato Pension',
      estatusDescargaPensionFlag: false,
      fechaContratoA: '',
      fechaContratoB: '',
      fechaContratoC: '',
      fechaContratoIndeterminado: ''
    }
  },
  methods: {
    obtenerEmpleado () {
      this.$http.get(`/api/empleado/${this.$route.params.id}`, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          // Contrato determinado A
          if (data.fecha_firma_contrato_determinado_a) {
            this.fechaContratoA = moment(data.fecha_firma_contrato_determinado_a).format('DD MMMM YYYY')
          }
          // Contrato determinado B
          if (data.fecha_firma_contrato_determinado_b) {
            this.fechaContratoB = moment(data.fecha_firma_contrato_determinado_b).format('DD MMMM YYYY')
          }
          // Contrato determinado C
          if (data.fecha_firma_contrato_determinado_c) {
            this.fechaContratoC = moment(data.fecha_firma_contrato_determinado_c).format('DD MMMM YYYY')
          }
          // Contrato Indeterminado
          if (data.fecha_firma_contrato_indeterminado) {
            this.fechaContratoIndeterminado = moment(data.fecha_firma_contrato_indeterminado).format('DD MMMM YYYY')
          }
        })
    },
    downloadContrato () {
      this.estatusDescargaContrato = 'Descargando...'
      this.estatusDescargaContratoFlag = true
      this.$http.get(`/api/empleado/${this.$route.params.id}/contrato-pdf`, {
        responseType: 'blob',
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `contrato_${this.$route.params.nombreempleado.replace(/ /g, '')}`
          link.click()
          URL.revokeObjectURL(link.href)
          this.estatusDescargaContrato = 'Generar Contrato Laboral'
          this.estatusDescargaContratoFlag = false
        })
    },
    downloadPension () {
      this.estatusDescargaPension = 'Descargando...'
      this.estatusDescargaPensionFlag = true
      this.$http.get(`/api/empleado/${this.$route.params.id}/pension-pdf`, {
        responseType: 'blob',
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          const blob = new Blob([data], { type: 'application/pdf' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = `fondopension_${this.$route.params.nombreempleado.replace(/ /g, '')}`
          link.click()
          URL.revokeObjectURL(link.href)
          this.estatusDescargaPension = 'Generar Contrato Pension'
          this.estatusDescargaPensionFlag = false
        })
    }
  },
  mounted () {
    this.obtenerEmpleado()
  }
}
</script>
