<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link :to="{ name: 'empleado-sueldos', params: { id: this.$route.params.id, nombreempleado: this.$route.params.nombreempleado } }" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-1 gap-6 mb-10">
      <div class="mt-5 md:mt-0 md:col-span-2">
        <form @submit.prevent="createSueldo">
          <div class="shadow sm:rounded-md">
            <div class="px-4 py-5 bg-white sm:p-6">
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-12">
                  <p class="uppercase text-base text-gray-700 font-medium tracking-wider">Actualizar Sueldo</p>
                </div>
              </div>
              <div class="grid grid-cols-12 gap-6 mb-6">
                <div class="col-span-3">
                  <label class="block text-sm leading-5 text-gray-700">Fecha</label>
                  <datepicker v-model="fechaSueldo" :language="es" :format="format"></datepicker>
                </div>
                <div class="col-span-2">
                  <label class="block text-sm leading-5 text-gray-700">Sueldo Diario</label>
                  <input v-model="sueldoDiario" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-2">
                  <label class="block text-sm leading-5 text-gray-700">Sueldo Periodo</label>
                  <input v-model="sueldoSemanal" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
                <div class="col-span-2">
                  <label class="block text-sm leading-5 text-gray-700">Sueldo Periodo Real</label>
                  <input v-model="sueldoSemanalReal" required class="mt-1 form-input block w-full py-1 px-3 border border-gray-400 rounded shadow-sm text-sm leading-6 focus:outline-none">
                </div>
              </div>
              <button class="py-1 px-4 border border-transparent text-sm leading-5 rounded text-white bg-green-500 hover:bg-green-600 focus:outline-none">
                Registrar Sueldo
              </button>
              <span v-show="errorCaptura" class="text-red-600 text-sm ml-6">Error al registrar sueldo, verificar datos.</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { es } from 'vuejs-datepicker/src/locale'
import moment from 'moment-timezone'
import authClient from '@/utils/auth'
export default {
  name: 'NuevoSueldo',
  components: {
    Datepicker
  },
  data () {
    return {
      es: es,
      format: 'd MMMM yyyy',
      fechaSueldo: '',
      sueldoDiario: 0,
      sueldoSemanal: 0,
      sueldoSemanalReal: 0,
      errorCaptura: false
    }
  },
  methods: {
    obtenerSueldo () {
      this.$http.post('/api/empleado/sueldo-empleado', { empleado: this.$route.params.id }, {
        headers: { Authorization: authClient.getAuthToken() }
      }).then(({ data }) => {
        this.sueldoDiario = data.sueldo_diario
        this.sueldoSemanal = data.sueldo_semanal
        this.sueldoSemanalReal = data.sueldo_semanal_real
      })
    },
    createSueldo () {
      let fecha = moment().format('YYYY-MM-DD')

      if (moment(this.fechaSueldo).isValid()) {
        fecha = moment(this.fechaSueldo).format('YYYY-MM-DD')
      }

      const sueldoObject = {
        fecha,
        sueldo_diario: this.sueldoDiario,
        sueldo_semanal: this.sueldoSemanal,
        sueldo_semanal_real: this.sueldoSemanalReal,
        empleadoId: this.$route.params.id
      }
      this.$http.post('/api/exp-sueldo/registrar-sueldo', sueldoObject, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(() => {
          this.$router.push({
            name: 'empleado-sueldos',
            params: {
              id: this.$route.params.id,
              nombreempleado: this.$route.params.nombreempleado
            }
          })
        })
        .catch(() => {
          this.errorCaptura = true
          setTimeout(() => (this.errorCaptura = false), 5000)
        })
    }
  },
  mounted () {
    this.obtenerSueldo()
  }
}
</script>
