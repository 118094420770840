<template>
  <div class="container mx-auto">
    <div class="flex items-center mb-6">
      <label for="empresa" class="block text-sm leading-5 font-medium text-gray-700 mr-4">Empresa</label>
      <div class="relative mr-4">
        <select v-model="empresaSeleccionada" @change="cambiarEmpresa" class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 text-sm py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
          <option v-for="(item, key) in empresas" :value="item.id" :key="key">{{item.nombre}}</option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
        </div>
      </div>
    </div>
    <div class="flex flex-row mb-6">
      <router-link to="/nomina" class="bg-gray-700 hover:bg-gray-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-4">Nomina</router-link>
      <router-link to="/turno" class="bg-teal-700 hover:bg-teal-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-4">Turno</router-link>
      <router-link to="/horario" class="bg-orange-600 hover:bg-orange-700 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-auto">Horarios</router-link>
      <router-link to="/empleado-nuevo" class="bg-blue-600 hover:bg-blue-700 text-white text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline">Nuevo Empleado</router-link>
    </div>
    <ListEmpleados :empleados="empleados" :sucursalSelected="sucursalSelected" :sucursales="sucursales" @onChangeSucursal="changeSucursal" @onSearchEmpleado="buscarEmpleado" :empleadoSearch="empleadoSearch" class="mb-6"/>
    <Pagination :count="empleadosCount" :activePage="activePage" @onChangePage="cambiarPaginacion" />
    <div class="flex flex-row mb-6">
      <router-link to="/bajas" class="bg-red-700 hover:bg-red-800 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-4">Inactivos</router-link>
      <router-link to="/externos" class="bg-gray-600 hover:bg-gray-700 text-white text-xs py-2 px-8 rounded focus:outline-none focus:shadow-outline mr-auto">Externos</router-link>
    </div>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import ListEmpleados from '@/components/ListEmpleados'
import Pagination from '@/components/Pagination'
export default {
  name: 'Home',
  components: {
    ListEmpleados,
    Pagination
  },
  data () {
    return {
      empresas: [],
      empresaSeleccionada: this.$store.getters.empresaSeleccionada,
      empleados: [],
      empleadosCount: 0,
      activePage: 0,
      empleadoSearch: '',
      sucursales: [],
      sucursalesEmpresa: [],
      sucursal: [],
      sucursalSelected: 0
    }
  },
  methods: {
    changeSucursal (sucursalSelected) {
      this.sucursal = []
      this.sucursalSelected = sucursalSelected
      if (parseInt(sucursalSelected) === 0) {
        this.sucursal = this.sucursalesEmpresa
      } else {
        this.sucursal.push(sucursalSelected)
      }
    },
    listaEmpresasAsignadasUsuario () {
      this.$http.post('/api/empresa/usuario-empresas', {}, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.empresas = data
            if (this.$store.getters.empresaSeleccionada === 0) {
              this.$store.dispatch('EMPRESA_SELECCIONADA', data[0].id)
              this.empresaSeleccionada = data[0].id
            }
            this.obtenerSucursales()
          }
        })
    },
    cambiarEmpresa () {
      this.$store.dispatch('EMPRESA_SELECCIONADA', this.empresaSeleccionada)
      this.activePage = 0
      this.empleadoSearch = ''
      this.sucursalSelected = 0
      this.obtenerSucursales()
    },
    obtenerSucursales () {
      this.sucursal = []
      this.$http.post('/api/sucursal/sucursales-empresa', { empresa: this.empresaSeleccionada }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.sucursales = data
            data.forEach(element => {
              this.sucursalesEmpresa.push(element.id)
              this.sucursal.push(element.id)
            })
          }
        })
    },
    listaEmpleadosEmpresa () {
      this.$http.post('/api/empleado/empleados-empresa', { empresa: this.empresaSeleccionada, page: this.activePage, empleadoSearch: this.empleadoSearch, empleadoStatus: true, sucursal: this.sucursal }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          this.empleados = data
        })
      this.$http.post('/api/empleado/empleados-empresa-count', { empresa: this.empresaSeleccionada, empleadoSearch: this.empleadoSearch, empleadoStatus: true, sucursal: this.sucursal }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          this.empleadosCount = data
        })
    },
    cambiarPaginacion (pageSelected) {
      this.activePage = pageSelected
      this.listaEmpleadosEmpresa()
    },
    buscarEmpleado (empleadoSearch) {
      this.empleadoSearch = empleadoSearch
      this.activePage = 0
    }
  },
  mounted () {
    this.listaEmpresasAsignadasUsuario()
  },
  watch: {
    sucursal (newVal, oldVal) {
      this.listaEmpleadosEmpresa()
    }
  }
}
</script>
