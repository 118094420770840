<template>
  <tr>
    <td class="px-4 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ solicitud.fecha }}</td>
    <td class="px-4 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ solicitud.hora_entrada }}</td>
    <td class="px-4 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ solicitud.colaborador }}</td>
    <td class="px-4 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ solicitud.nombre }}</td>
    <td class="px-4 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ solicitud.motivo }}</td>
    <td class="px-4 py-3 whitespace-no-wrap text-sm leading-4 font-medium">
      <button @click="updateConsultaRevisada(solicitud.id, false)" class="text-red-600 hover:text-red-900 focus:outline-none">Rechazar</button>
    </td>
    <td class="px-4 py-3 whitespace-no-wrap text-sm leading-4 font-medium">
      <button @click="updateConsultaRevisada(solicitud.id, true)" class="text-green-600 hover:text-green-900 focus:outline-none">Autorizar</button>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ItemTurnoAjustes',
  props: {
    solicitud: Object
  },
  methods: {
    updateConsultaRevisada (consultaId, consultaStatus) {
      this.$emit('onConsultaRevisada', consultaId, consultaStatus)
    }
  }
}
</script>
