<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <router-link to="/turno" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800">{{ sucursal }}</h4>
        <span class="text-xs text-gray-500" v-if="registroTurnoSucursal.length > 0">{{ Object.keys(registroTurnoSucursal[0].semana)[0] | moment("DD MMMM YYYY") }} - {{ Object.keys(registroTurnoSucursal[0].semana)[6] | moment("DD MMMM YYYY") }}</span>
      </div>
    </div>
    <ListTurnoDetalle :registroTurnoSucursal="registroTurnoSucursal" class="mb-6"/>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import moment from 'moment-timezone'
import ListTurnoDetalle from '../components/ListTurnoDetalle.vue'
export default {
  name: 'TurnoDetalle',
  components: { ListTurnoDetalle },
  data () {
    return {
      sucursalId: this.$route.params.sucursalId,
      sucursal: this.$route.params.sucursal,
      fechaTurno: this.$store.getters.getFechaTurno,
      registroTurnoSucursal: []
    }
  },
  methods: {
    consultaTurnoDetalle () {
      const fecha = moment(this.fechaTurno).format('YYYY-MM-DD')
      this.$http.post('/api/turno-laborado/empleado-turno-detalle', { sucursal: this.sucursalId, fecha }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          this.registroTurnoSucursal = data
        })
    }
  },
  mounted () {
    this.consultaTurnoDetalle()
  }
}
</script>
