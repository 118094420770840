<template>
  <tr>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ descuento.fecha | moment("DD MMMM YYYY") }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ descuento.categoria }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ descuento.monto }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ descuento.motivo }}</td>
    <td class="px-6 py-3 whitespace-no-wrap text-sm leading-4 font-medium text-gray-900">{{ descuento.semana.semana }} - {{ descuento.semana.periodo }}</td>
  </tr>
</template>

<script>
export default {
  name: 'ItemDescuento',
  props: {
    descuento: Object
  }
}
</script>
