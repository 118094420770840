<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/nomina" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar</router-link>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800">Nomina {{ semana.semana }} - {{ semana.periodo }}</h4>
        <span class="text-xs text-gray-500">{{ semana.fecha_inicial | moment("DD MMMM YYYY") }} - {{ semana.fecha_final | moment("DD MMMM YYYY") }}</span>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-9">
        <p class="font-medium text-lg text-gray-600">Nomina Fiscal</p>
      </div>
      <div class="col-span-3" v-show="registros.length > 0">
        <select v-model="sucursal" @change="changeSucursal" class="mt-1 block form-select w-full py-2 px-3 border border-gray-400 bg-white rounded shadow-sm focus:outline-none text-xs">
          <option v-for="(item, key) in sucursales" :value="item.id" :key="key">{{item.nombre}}</option>
        </select>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6" v-show="registros.length === 0">
      <form class="col-span-5 justify-self-end">
        <label class="w-48 flex flex-row items-center justify-center px-4 py-3 bg-white text-blue rounded-lg shadow-lg tracking-wide uppercase border border-blue cursor-pointer hover:bg-blue-800 hover:text-white">
          <svg v-show="!uploadFile" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload">
            <path d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M17 8l-5-5-5 5M12 3v12"/>
          </svg>
          <svg v-show="uploadFile" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-loader">
            <path d="M12 2v4M12 18v4M5 5l3 3M16 16l3 3M2 12h4M18 12h4M5 19l3-3M16 8l3-3"/>
          </svg>
          <span class="pl-4 text-xs leading-normal">{{ (uploadFile) ?  'Cargando...': 'Subir archivo' }}</span>
          <input ref="fileupload" type='file' :disabled="uploadFile" class="hidden" @change="processFile($event)" />
        </label>
      </form>
      <div class="col-span-3 self-center">
        <span v-show="errorCaptura" class="text-sm text-red-700">Error: revisar formato de archivo</span>
      </div>
    </div>
    <ListNominaFiscal :registros="registros" class="mb-6" v-show="registros.length > 0" />
  </div>
</template>

<script>
import ListNominaFiscal from '@/components/ListNominaFiscal'
import authClient from '@/utils/auth'
export default {
  name: 'NominaFiscal',
  components: {
    ListNominaFiscal
  },
  data () {
    return {
      semanaId: this.$route.params.id,
      empresa: this.$store.getters.empresaSeleccionada,
      semana: {
        semana: 1,
        periodo: 2020,
        fecha_inicial: '2020-01-01',
        fecha_final: '2020-01-01'
      },
      registros: [],
      errorCaptura: false,
      sucursales: [],
      sucursal: 0,
      uploadFile: false
    }
  },
  methods: {
    obtenerSucursales () {
      this.$http.post('/api/sucursal/sucursales-empresa', { empresa: this.empresa }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => {
          if (data.length > 0) {
            this.sucursales = data
            this.sucursal = data[0].id
            this.obtenerRegistros()
          }
        })
    },
    obtenerSemana () {
      this.$http.get(`/api/semanas/${this.semanaId}`)
        .then(({ data }) => (this.semana = data))
    },
    processFile (event) {
      this.uploadFile = true
      if (event.target.files[0].type !== 'text/csv') {
        this.uploadFile = false
        this.errorCaptura = true
        setTimeout(() => (this.errorCaptura = false), 5000)
        return null
      }
      const formData = new FormData()
      formData.append('file', event.target.files[0])
      formData.append('semana', this.semanaId)
      formData.append('empresa', this.empresa)
      this.$http.post('/api/turno-nomina/incidencias-csv', formData, {
        headers: {
          Authorization: authClient.getAuthToken(),
          'Content-Type': 'multipart/form-data'
        }
      })
        .then(({ data }) => this.obtenerRegistros())
        .catch(() => {
          this.$refs.fileupload.value = null
          this.errorCaptura = true
          setTimeout(() => (this.errorCaptura = false), 5000)
        })
    },
    obtenerRegistros () {
      this.$http.post('/api/nomina-fiscal/registros-csv', { semana: this.semanaId, empresa: this.empresa, sucursal: this.sucursal }, {
        headers: { Authorization: authClient.getAuthToken() }
      })
        .then(({ data }) => (this.registros = data))
        .catch(() => {})
    },
    changeSucursal () {
      this.obtenerRegistros()
    }
  },
  mounted () {
    this.obtenerSemana()
    this.obtenerSucursales()
  }
}
</script>
