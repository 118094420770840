<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <router-link to="/externos" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de externos</router-link>
    </div>
    <div class="mb-4">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <span class="ml-1 text-base font-medium text-gray-800 leading-5">
            {{ registroEmpleado }}
          </span>
          <span v-show="empleado.activo" class="ml-3 text-xs font-normal uppercase text-white bg-green-600 px-6 py-1 rounded-xl">Activo</span>
          <span v-show="!empleado.activo" class="ml-3 text-xs font-normal uppercase text-white bg-red-600 px-6 py-1 rounded-xl">Baja</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-4">
        <label class="block text-sm leading-5 text-gray-700">Número de Externo</label>
        <input v-model="empleado.numero_empleado" readonly class="input-readonly">
      </div>
    </div>
    <div class="grid grid-cols-10 gap-6 mb-6">
      <div class="col-span-4">
        <label class="block text-sm leading-5 text-gray-700">Primer Nombre</label>
        <input v-model="empleado.primer_nombre" readonly class="input-readonly">
      </div>
      <div class="col-span-4">
        <label class="block text-sm leading-5 text-gray-700">Segundo Nombre</label>
        <input v-model="empleado.segundo_nombre" readonly class="input-readonly">
      </div>
      <div class="col-span-4">
        <label class="block text-sm leading-5 text-gray-700">Apellido Paterno</label>
        <input v-model="empleado.apellido_paterno" readonly class="input-readonly">
      </div>
      <div class="col-span-4">
        <label class="block text-sm leading-5 text-gray-700">Apellido Materno</label>
        <input v-model="empleado.apellido_materno" readonly class="input-readonly">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">CURP</label>
        <input v-model="empleado.curp" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">INE</label>
        <input v-model="empleado.ine" readonly class="input-readonly">
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Fecha Nacimiento</label>
        <input v-model="fechaNacimiento" readonly class="input-readonly">
      </div>
      <div class="col-span-5">
        <label class="block text-sm leading-5 text-gray-700">Lugar Nacimiento</label>
        <input v-model="empleado.lugar_nacimiento" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Sexo</label>
        <input v-model="empleado.sexo" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Nacionalidad</label>
        <input v-model="empleado.nacionalidad" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Estado Civil</label>
        <input v-model="empleado.estado_civil" readonly class="input-readonly">
      </div>
      <hr class="col-span-12"/>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Fecha Ingreso</label>
        <input v-model="fechaIngreso" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Sucursal</label>
        <input v-model="sucursal" readonly class="input-readonly">
      </div>
      <hr class="col-span-12"/>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-4">
        <label class="block text-sm leading-5 text-gray-700">Correo Electrónico</label>
        <input v-model="empleado.correo_electronico" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Teléfono Celular</label>
        <input v-model="empleado.telefono_celular" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Teléfono Casa</label>
        <input v-model="empleado.telefono_casa" readonly class="input-readonly">
      </div>
      <hr class="col-span-12"/>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-6">
      <div class="col-span-6">
        <label class="block text-sm leading-5 text-gray-700">Domicilio Calle</label>
        <input v-model="empleado.domicilio_calle" readonly class="input-readonly">
      </div>
      <div class="col-span-2">
        <label class="block text-sm leading-5 text-gray-700">No Exterior</label>
        <input v-model="empleado.domicilio_noexterior" readonly class="input-readonly">
      </div>
      <div class="col-span-2">
        <label class="block text-sm leading-5 text-gray-700">No Interior</label>
        <input v-model="empleado.domicilio_nointerior" readonly class="input-readonly">
      </div>
      <div class="col-span-4">
        <label class="block text-sm leading-5 text-gray-700">Colonia</label>
        <input v-model="empleado.domicilio_colonia" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Municipio</label>
        <input v-model="empleado.domicilio_municipio" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Entidad Federativa</label>
        <input v-model="empleado.domicilio_entidad_federativa" readonly class="input-readonly">
      </div>
      <div class="col-span-2">
        <label class="block text-sm leading-5 text-gray-700">Codigo Postal</label>
        <input v-model="empleado.domicilio_codigo_postal" readonly class="input-readonly">
      </div>
      <hr class="col-span-12"/>
    </div>
    <div class="grid grid-cols-12 gap-6 mb-12">
      <div class="col-span-6">
        <label class="block text-sm leading-5 text-gray-700">Referencia 1 Nombre</label>
        <input v-model="empleado.referencia_personal_uno_nombre" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Referencia 1 Teléfono</label>
        <input v-model="empleado.referencia_personal_uno_telefono" readonly class="input-readonly">
      </div>
      <div class="col-span-6">
        <label class="block text-sm leading-5 text-gray-700">Referencia 2 Nombre</label>
        <input v-model="empleado.referencia_personal_dos_nombre" readonly class="input-readonly">
      </div>
      <div class="col-span-3">
        <label class="block text-sm leading-5 text-gray-700">Referencia 2 Teléfono</label>
        <input v-model="empleado.referencia_personal_dos_telefono" readonly class="input-readonly">
      </div>
    </div>
  </div>
</template>

<script>
import authClient from '@/utils/auth'
import moment from 'moment-timezone'
export default {
  name: 'EmpleadoExterno',
  data () {
    return {
      empleado: {},
      registroEmpleado: ''
    }
  },
  mounted () {
    this.$http.get(`/api/empleado-externo/${this.$route.params.id}`, {
      headers: {
        Authorization: authClient.getAuthToken()
      }
    })
      .then(({ data }) => {
        this.empleado = data
        this.registroEmpleado = `${data.numero_empleado} - ${data.primer_nombre} ${data.segundo_nombre} ${data.apellido_paterno} ${data.apellido_materno}`
      })
  },
  computed: {
    infonavit: {
      get () {
        return (this.empleado.infonavit) ? 'Si' : 'No'
      }
    },
    fechaNacimiento: {
      get () {
        return moment(this.empleado.fecha_nacimiento).format('DD MMMM YYYY')
      }
    },
    fechaIngreso: {
      get () {
        return moment(this.empleado.fecha_ingreso).format('DD MMMM YYYY')
      }
    },
    sucursal: {
      get () {
        return (this.empleado.sucursal) ? this.empleado.sucursal.nombre : ''
      }
    }
  }
}
</script>
