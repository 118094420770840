<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
        </div>
        <div class="flex">
          <span class="px-4 text-xs font-medium text-gray-800 bg-gray-200 border rounded-md border-transparent py-1">{{ this.$route.params.nombreempleado }}</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Prima Vacacional</h4>
      </div>
      <div class="col-span-3 justify-self-end">
        <NavExpediente :registroEmpleado="this.$route.params.nombreempleado" :empleadoId="this.$route.params.id" />
      </div>
    </div>
    <ListPrimaVacacional :primavacacional="primavacacional" class="mb-6"/>
  </div>
</template>

<script>
import NavExpediente from '@/components/NavExpediente'
import authClient from '@/utils/auth'
import ListPrimaVacacional from '@/components/ListPrimaVacacional'
export default {
  name: 'EmpleadoPrimaVacacional',
  components: {
    NavExpediente,
    ListPrimaVacacional
  },
  data () {
    return {
      primavacacional: []
    }
  },
  methods: {
    obtenerPrimaVacacional () {
      this.$http.post('/api/exp-prima-vacacional/primavacacional-empleado', { empleado: this.$route.params.id }, {
        headers: {
          Authorization: authClient.getAuthToken()
        }
      }).then(({ data }) => (this.primavacacional = data))
    }
  },
  mounted () {
    this.obtenerPrimaVacacional()
  }
}
</script>
