<template>
  <div class="container mx-auto">
    <div class="mb-6">
      <div class="flex justify-between mb-4">
        <div class="flex-1 min-w-0">
          <router-link to="/" class="inline-flex text-gray-700 hover:text-gray-900 border px-4 py-1 rounded text-xs border-gray-600 hover:bg-gray-300">Regresar a lista de empleados</router-link>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-11 gap-6">
      <div class="col-span-9">
        <h4 class="font-semibold text-xl text-gray-800 mb-6">Nomina</h4>
      </div>
    </div>
    <ListSemanaNomina :semanas="semanas" class="mb-6"/>
  </div>
</template>

<script>
import ListSemanaNomina from '@/components/ListSemanaNomina'
export default {
  name: 'Nomina',
  components: {
    ListSemanaNomina
  },
  data () {
    return {
      semanas: []
    }
  },
  methods: {
    obtenerSemanas () {
      this.$http.post('/api/semanas/semanas-ultimas', { empresa: this.empresaSeleccionada })
        .then(({ data }) => {
          if (data.length > 0) {
            this.semanas = data
          }
        })
    }
  },
  mounted () {
    this.obtenerSemanas()
  }
}
</script>
